import React, { Component } from 'react'
import '../../css/CartaProducto.css'
import '../../css/scrollBar.css'


export default class ItemCardNoPrices extends Component {

    selectItem(item){
        const Padre = this.props.Padre
        Padre.setState({selectedItem: item})
    }    

    render() {
        return (
            <div className="conteiner pb-3 cartaHover font-family-arial" onClick={() => this.selectItem(this.props.objectProd)}>
                <div className="row item-card pt-2">
                    <div className="col-4 imgC ">
                        <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                    </div>
                    <div className="col-8 descriptionMargin">
                        <div className='row'>
                            <h5>
                                <span class="badge bg-primary me-3">&nbsp;{this.props.ItemCode}&nbsp;</span>
                                <span class="badge bg-secondary">OnHand: {this.props.OnHand ? this.props.OnHand : 0}</span>
                            </h5>
                        </div>
                        <div className="text-start">
                            <p className="card-text fs-6">{this.props.Description}</p>
                        </div>

                    </div>
                </div>
            </div>
        )

    }
}


