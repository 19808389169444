import React, { Component } from "react";
import Footer from "../components/Footer";
import { MenuCompany } from "../components/MenuCompanySelect";
import "../css/companyDash-style.css"
import { getInformationWithData } from "../services/CABE";
import { getValueCookie, setNewCookie } from "../services/cookieService";
import { v4 as uuidv4 } from 'uuid';
import { GetNumberOfOrders } from "../functions/pagesFunction";
export default class CompanyDashBoard extends Component {

    state = {
        General: {
            CompanysofUser: []
        }
    }

    async getCompanysAssigned() {
        const data = {
            userName: getValueCookie('userName')
        }
        const info = await getInformationWithData('/company/CatalogueofCompanies', data)
        if (info.status.code === 1) {
            const temporal = this.state.General
            temporal.CompanysofUser = info.data
            this.setState({ General: temporal })
        }
    }

    async SelectCompany(company) {
        setNewCookie('Company', company.idCompany === 3 || company.idCompany === 5 ? 'Mayaland Atlanta' : company.name, 50)
        setNewCookie('CompanyId', company.idCompany === 3 || company.idCompany === 5 ? 3 : company.idCompany, 50)
        if (company.idCompany === 5) {
            setNewCookie('SubCompanyId', company.idCompany, 50)
        }
        setNewCookie('CompanyLogo', company.directoryLogo, 50)
        setNewCookie('DocumentsLogo', company.optionalDirectoryLogo, 50)
        setNewCookie('TypeUser', company.idTypeofUser, 50)
        setNewCookie('SalesPerson', company.salesPerson, 50)

        if (company.idCompany === 3) {
            let total = await GetNumberOfOrders(company.salesPerson)
            setNewCookie('TotalOrders', total, 50)
        }

        const data = {
            companyid: company.idCompany
        }
        const information = await getInformationWithData('/company/information', data)
        if (information.status.code === 1) {
            setNewCookie('fullName', information.data[0].fullname, 50)
            setNewCookie('address', information.data[0].address, 50)
            setNewCookie('warehouse', information.data[0].city + " Warehouse", 50)
            setNewCookie('website', information.data[0].webSite, 50)
            setNewCookie('phone', information.data[0].phone, 50)
        }
        window.localStorage.setItem('session', uuidv4())
        window.location.reload()
    }

    render() {
        return (
            <div id="companydash" className="d-flex flex-column">
                <button hidden id="catalogueOfCompanyActioner" onClick={() => this.getCompanysAssigned()}></button>
                <MenuCompany />
                <main className="main-content">
                    <div className="container-fluid generalContainer" id="actualPage">
                        <div className="text-center">
                            <h3 className="company-title">Welcome!</h3>
                            <p className="company-text">Please select your company to proceed.</p>
                        </div>
                        <div className="container px-4">
                            <div className="row gx-5 justify-content-center">
                                {this.state.General.CompanysofUser.map((company, i) => (
                                <div key={i} className="col-sm-3 p-3 pb-4">
                                    <div 
                                    className="card company-card text-center" 
                                    onClick={() => this.SelectCompany(company)}
                                    >
                                    <div className="card-body">
                                        <div className="company-logo mb-3">
                                        <img 
                                            src={company.directoryLogo} 
                                            alt={`${company.name} logo`} 
                                            className="img-fluid"
                                        />
                                        </div>
                                        <h5 className="company-name">{company.name}</h5>
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}