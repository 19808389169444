import React, { Component } from 'react'
import ProgressBar from '../components/ProgressBar'
import { AiOutlineSearch, AiFillCalendar, AiOutlineComment, AiOutlineCheck } from "react-icons/ai"
import '../css/table-responsive.css'
import '../css/general-style.css'
import '../css/cycleInventory.css'
import ModalOrders from '../components/ModalComponent';
import { create_Delete_Update_Information, getInformationWithData } from '../services/CABE';
import Swal from "sweetalert2";
import { getValueCookie } from '../services/cookieService';
import { automaticCloseAlert } from '../functions/alerts'
import { CompareDates, DateFormatMAS90, formatInputDate, formatInputDateQuery, FormatQueryReturnDate, FormatQueryReturnDateWithDash, getActualDateUTC, getDateFromReports, getDateYearMonthDayDash, OrderArrayByDate } from '../functions/dateFormat'
import { getDataSet } from '../functions/generateDataSetExcel'
import ExcelDocument from '../components/ExcelDocument'
import Select from 'react-select';
import { getIndexElement } from '../functions/searchInObject'
import LoadingWindow from '../components/LoadingWindow';
import { ItemDesc } from '../functions/generalFunctions'

export default class CycleInvetory extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
        this.ProgrressBarRef = React.createRef();
    }

    state = {
        dataset: [{
            columns: [],
            data: []
        }],
        porcetaje: 0,
        General: {
            showModal1: false,
            showModal2: false,
            showModal3: false,
            showModal4: false,
            habilitar: false,
            chekvalue: '0',
            checkHistory: '0',
            selectedItem: '',
            selectedCycleInventory: null,
            secureTransaction: false,
            generalHistory: [],
            outBounds: [],
            purchaseOrders: [],
            generalHistoryFilter: [],
            oldCycleInventory: [],
            detailOldCycleSelected: [],
            detailOldCycleSelectedFilter: [],
            optionsSelect1: [{ value: 'All', label: 'All' }],


        },

        cycleInventoryStorage: {
            Header: {
                closeBy: null,
                createBy: '',
                days: 0,
                finishDate: '',
                id: 0,
                lastUpadeDate: '',
                realFinishDate: '',
                startDate: '',
                status: 0,
                updateBy: '',
                QuantityItems: 0,
                FilterQuantity: 0
            },
            Detail: [],
            DetailFilter: [],
            CheckedItems: [],
            actualTitleSearch: 'No Checked Items'
        },
        secureTransaction1: false

    }
    valueSearchBar = async e => {
        let search = e.target.value
        this.getBySearchBar(search)
    }

    valueRadioButton = async e => {
        document.getElementById('searchCycleInv1').value = ""
        let stat = e.target.value
        const temporal = this.state.General
        temporal.chekvalue = stat
        this.setState({ General: temporal })
        this.getByStat(stat)
    }



    searchOlderCycleInventoryDetail = e => {
        let search = e.target.value


        var DetailFilter = this.state.General.detailOldCycleSelected.filter((item) => {
            if (item.ItemCode.toString().toLowerCase().includes(search.toLowerCase()) || item.BIN.toString().toLowerCase().includes(search.toLowerCase()) || item.status.toString().toLowerCase().includes(search.toLowerCase())) {
                return item
            } else {
                return null
            }
        })

        const temporal = this.state.General
        temporal.detailOldCycleSelectedFilter = DetailFilter

        this.setState({ General: temporal })
    }



    getBySearchBar(search) {

        if (search !== "" && this.state.General.chekvalue !== '-1') {
            if (this.state.General.chekvalue === '0' || this.state.General.chekvalue === '1') {
                var DetailFilter = this.state.cycleInventoryStorage.Detail.filter((item) => {
                    if ((item.ItemCode.toString().toLowerCase().includes(search.toLowerCase()) || item.productLine.toString().toLowerCase().includes(search.toLowerCase()) || item.BIN.toString().toLowerCase().includes(search.toLowerCase()) || item.Description.toString().toLowerCase().includes(search.toLowerCase())) && item.status.toString().toLowerCase().includes(this.state.General.chekvalue)) {
                        return item
                    } else {
                        return null
                    }
                })

                const temporal = this.state.cycleInventoryStorage
                temporal.DetailFilter = DetailFilter
                temporal.Header.FilterQuantity = DetailFilter.length
                this.setState({ cycleInventoryStorage: temporal })
            } else if (this.state.General.chekvalue === '2') {
                var DetailFilter4 = this.state.cycleInventoryStorage.Detail.filter((item) => {
                    if ((item.ItemCode.toString().toLowerCase().includes(search.toLowerCase()) || item.productLine.toString().toLowerCase().includes(search.toLowerCase()) || item.BIN.toString().toLowerCase().includes(search.toLowerCase()) || item.Description.toString().toLowerCase().includes(search.toLowerCase())) && item.status.toString().toLowerCase().includes("1") && item.difference !== 0) {
                        return item
                    } else {
                        return null
                    }
                })

                const temporal = this.state.cycleInventoryStorage
                temporal.DetailFilter = DetailFilter4
                temporal.Header.FilterQuantity = DetailFilter4.length
                this.setState({ cycleInventoryStorage: temporal })
            }
        } else if (search !== "") {

            var DetailFilter2 = this.state.cycleInventoryStorage.Detail.filter((item) => {
                if ((item.ItemCode.toString().toLowerCase().includes(search.toLowerCase()) || item.BIN.toString().toLowerCase().includes(search.toLowerCase()) || item.productLine.toString().toLowerCase().includes(search.toLowerCase()) || item.Description.toString().toLowerCase().includes(search.toLowerCase()))) {
                    return item
                } else {
                    return null
                }
            })

            const temporal = this.state.cycleInventoryStorage
            temporal.DetailFilter = DetailFilter2
            temporal.Header.FilterQuantity = DetailFilter2.length
            this.setState({ cycleInventoryStorage: temporal })

        } else {
            this.getByStat(this.state.General.chekvalue)
        }

    }

    getByStat(stat) {
        if (stat !== "-1") {
            if (stat === "0" || stat === "1") {
                var DetailFilter = this.state.cycleInventoryStorage.Detail.filter((item) => {
                    if (item.status.toString().toLowerCase().includes(stat.toLowerCase())) {
                        return item
                    } else {
                        return null
                    }
                })
                const temporal = this.state.cycleInventoryStorage
                if (stat === '0') {
                    temporal.actualTitleSearch = 'No Checked Items'
                } else {
                    temporal.actualTitleSearch = 'Checked Items'
                }
                temporal.DetailFilter = DetailFilter
                temporal.Header.FilterQuantity = DetailFilter.length
                this.setState({ cycleInventoryStorage: temporal })
            } else if (stat === "2") {

                var DetailFilter3 = this.state.cycleInventoryStorage.Detail.filter((item) => {
                    if (item.difference !== 0) {
                        return item
                    } else {
                        return null
                    }
                })
                const temporal = this.state.cycleInventoryStorage
                temporal.actualTitleSearch = 'Differences'
                temporal.DetailFilter = DetailFilter3
                temporal.Header.FilterQuantity = DetailFilter3.length
                this.setState({ cycleInventoryStorage: temporal })

            }


        } else {
            const temporal = this.state.cycleInventoryStorage
            temporal.actualTitleSearch = 'All items'
            temporal.DetailFilter = temporal.Detail
            temporal.Header.FilterQuantity = temporal.Detail.length
            this.setState({ cycleInventoryStorage: temporal })
        }

    }


    getCategory(stat) {
        var busqueda = ""
        switch (stat) {
            case "0":
                busqueda = "purchase"
                break;
            case "1":
                busqueda = "transferencia"
                break;
            case "2":
                busqueda = "ajuste"
                break;
            case "3":
                busqueda = "outbound"
                break;
            default:
                busqueda = ""
                break;
        }
        return busqueda;
    }



    async componentDidMount() {
        this.ProgrressBarRef.current.setValue(0);

    }

    async getLastCycleInventory() {
        this.ModalLoading.current.showState(true);
        const data = {
            idcompany: getValueCookie('CompanyId')
        }
        const route = '/inventory/lastCycle/post';
        const datos = await getInformationWithData(route, data)

        if (datos.status.code === 1) {
            if (datos.data.length > 0) {
                const temporal = this.state.cycleInventoryStorage
                temporal.Header = datos.data[0]

                if (temporal.Header.status === 0) {

                    await this.getDetailCycleInventory(temporal.Header.id, "actual")
                } else {
                    temporal.Detail = []
                    temporal.DetailFilter = []
                    await this.setState({ cycleInventoryStorage: temporal })
                }
            } else {
                const temporal = this.state.cycleInventoryStorage
                temporal.Header.status = 1
                await this.setState({ cycleInventoryStorage: temporal })
            }
        }
        this.ModalLoading.current.showState(false);

    }


    async getOldCycleInventory() {
        const data = {
            idcompany: getValueCookie('CompanyId')
        }
        const route = '/invertory/oldCycleInventorys/post';
        const datos = await getInformationWithData(route, data)
        if (datos.status.code === 1) {
            if (datos.data.length > 0) {
                const temporal = this.state.General
                temporal.oldCycleInventory = datos.data
                await this.setState({ General: temporal })

            } else {
                const temporal = this.state.General
                temporal.oldCycleInventory = []
                await this.setState({ General: temporal })
            }
        }
    }

    mergeDataCheck(arrayChecked, allArray) {

        for (let a = 0; a < arrayChecked.length; a++) {
            for (let b = 0; b < allArray.length; b++) {
                if (arrayChecked[a].ItemCode === allArray[b].ItemCode && arrayChecked[a].status === 1) {
                    allArray[b] = arrayChecked[a]
                    break
                }
            }

        }
        return allArray
    }


    async getDetailCycleInventory(id, type) {
        const data = {
            id: id,
            idcompany: getValueCookie('CompanyId'),
            company: getValueCookie('Company')
        }

        const datos = await getInformationWithData('/inventory/cycledetail/post', data)

        if (type === "actual") {
            const allinformation = await getInformationWithData('/inventory/allProductsCycle/post', data)
            
            if (datos.status.code === 1 && allinformation.status.code === 1) {
                let realData = this.mergeDataCheck(datos.data, allinformation.data)
                realData = await ItemDesc(realData, 'Description', 'ItemCode')
                console.log(realData)
                const temporal = this.state.cycleInventoryStorage
                temporal.Detail = realData
                temporal.DetailFilter = realData
                temporal.Header.QuantityItems = realData.length
                temporal.Header.FilterQuantity = realData.length
                await this.setState({ cycleInventoryStorage: temporal })
                const tempo = this.state.cycleInventoryStorage
                tempo.CheckedItems = await this.getCheckedItems()
                await this.setState({ cycleInventoryStorage: tempo })
                await this.getByStat('0')
                await this.completePercentage()
            }
        } else if (type === "old") {
            const temporal = this.state.General
            temporal.detailOldCycleSelected = datos.data
            temporal.detailOldCycleSelectedFilter = datos.data
            await this.setState({ General: temporal })
        }

    }
    getOldDetailCycleInventory(item) {
        const temporal = this.state.General
        temporal.selectedCycleInventory = item
        this.setState({ General: temporal })
        this.getDetailCycleInventory(item.id, "old")
    }


    enableTransaction() {
        const temporal = this.state.General
        temporal.secureTransaction = false
        this.setState({ General: temporal })
    }

    async disableTransaction() {
        const temporal = this.state.General
        temporal.secureTransaction = true
        await this.setState({ General: temporal })
    }


    async startNewCycleInventory() {
        this.disableTransaction()
        const data = {
            days: 0,
            idcompany: getValueCookie('CompanyId'),
            userName: getValueCookie('userName'),
            realDays: 0,
            CycleInfo: ''

        }
        await Swal.fire({
            title: 'New Cyclical Inventory',
            html: `
            
                <div class='text-start pText'>
                <p>Number of Days of Cyclical Inventory:</p>
                </div>
                <div class='text-center'>
                <input class="swal2-input text-center" id='CycleInvEstDays' type="number"/>
                </div>
                <div class='text-start pText'>
                <p>Cycle:</p>
                </div>
                <div class='text-center'>
                <input class="swal2-input text-center" id='CycleInvInfo' type="text"/>
                </div>                
           
    `,
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                let days = Swal.getPopup().querySelector('#CycleInvEstDays').value
                let CycleInfo = Swal.getPopup().querySelector('#CycleInvInfo').value
                data.days = days
                data.CycleInfo = CycleInfo
                var actualDate = new Date();
                var finishDate = new Date(actualDate.getTime() + Number(Number(days) * 86400000))
                var habilDays = 0
                while (actualDate <= finishDate) {
                    if (actualDate.getDay() === 0 || actualDate.getDay() === 6) {
                        habilDays++
                    }
                    actualDate = new Date(actualDate.getTime() + 86400000)
                }

                data.realDays = Number(data.days) + Number(habilDays)
                return await create_Delete_Update_Information('/invertory/newCycle/post', data)
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {

            if (result.isConfirmed) {
                await this.getLastCycleInventory()
                await Swal.fire({
                    title: `The Cyclical Inventory was created!`,
                })

            }
        })
        await this.enableTransaction()
    }

    async endCycleInventory() {
        this.disableTransaction()
        const data = {
            id: this.state.cycleInventoryStorage.Header.id,
            userName: getValueCookie('userName')
        }
        await Swal.fire({
            title: 'Do you want to end the actual Cyclical Inventory?',
            showDenyButton: true,
            backdrop: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result !== null && result !== undefined) {
                if (result.isConfirmed) {
                    const res = await create_Delete_Update_Information('/invertory/closeCycle/post', data)
                    if (res.status.code === 1) {
                        await this.getLastCycleInventory()
                        Swal.fire('Cyclical inventory are closed!', '', 'success')
                        this.setState({ porcetaje: 0 })
                        this.ProgrressBarRef.current.setValue(0);

                    } else {
                        Swal.fire('Cyclical inventory could not be closed', '', 'error')
                    }

                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            }

        })
        this.enableTransaction()
    }

    async setCycleInventoryDetailInfo(item, idQuant) {
        this.ModalLoading.current.showState(true);
        this.disableTransaction()
        const data = {
            ItemCode: item.ItemCode,
            BIN: item.BIN,
            company: getValueCookie('Company'),
            idCompany:getValueCookie('CompanyId')
        }

        const resultquant = await getInformationWithData('/invertory/getQuantity/post', data)
        if (resultquant.status.code === 1) {
            const temporal = this.state.cycleInventoryStorage
            const index = temporal.Detail.indexOf(item)
            var flag = true
            if (index !== -1) {

                if (temporal.Detail[index].idcycleInventoryHeader !== 0) {
                    flag = false
                }

                temporal.Detail[index].idcycleInventoryHeader = this.state.cycleInventoryStorage.Header.id

                temporal.Detail[index].countBy = getValueCookie('userName')
                temporal.Detail[index].date = getActualDateUTC()
                temporal.Detail[index].systemQuantity = resultquant.data[0].Quantity
                temporal.Detail[index].difference = temporal.Detail[index].realQuantity - resultquant.data[0].Quantity
                temporal.Detail[index].status = 1
                var response
                if (flag) {
                    response = await create_Delete_Update_Information('/invertory/insertDetailCycle/post', temporal.Detail[index])
                } else {
                    response = await create_Delete_Update_Information('/invertory/updateDetailCycle/post', temporal.Detail[index])
                }
                if (response.status.code === 1) {
                    await this.ModalLoading.current.showState(false);
                    automaticCloseAlert('correct', 'The item was check!')
                    await this.setState({ cycleInventoryStorage: temporal })
                    await this.completePercentage()
                } else {
                    await this.ModalLoading.current.showState(false);
                    automaticCloseAlert('incorrect', 'The item was not checked')
                    temporal.Detail[index].status = 0
                    temporal.Detail[index].countBy = ''
                }

            }
            const tempo = this.state.cycleInventoryStorage
            tempo.CheckedItems = await this.getCheckedItems()
            await this.setState({ cycleInventoryStorage: tempo })
            await this.enableTransaction()
        }

    }

    async updateCycleInventoryDetail(item) {
        const temporal = this.state.cycleInventoryStorage
        const index = temporal.Detail.indexOf(item)
        if (index !== -1) {
            temporal.Detail[index].countBy = null
            temporal.Detail[index].status = 0
            await this.setState({ cycleInventoryStorage: temporal })
        }

    }
    completePercentage() {
        this.setState({ porcetaje: 0 })
        var count = 0;
        var total = this.state.cycleInventoryStorage.Detail.length

        for (const item of this.state.cycleInventoryStorage.Detail) {
            if (item.status === 1)
                count++
        }

        for (let a = 0; a < ((count * 100) / total); a++) {
            this.addValue()
        }


    }



    textStatus(value) {
        if (value === 0) {
            return "Not checked"
        }
        return "Checked"
    }





    addValue() {
        let cantidad = this.state.porcetaje;
        cantidad++;
        this.setState({ porcetaje: cantidad });
        this.ProgrressBarRef.current.setValue(cantidad);
    }

    handleModalOpen = (modal) => {
        const temporal = this.state.General
        temporal[modal] = true
        this.setState({ General: temporal })
    }

    handleModalClose = (modal) => {
        const temporal = this.state.General
        temporal[modal] = false
        this.setState({ General: temporal })
    }


    async openOlCycleInventory() {
        const temporal = this.state.General
        temporal.detailOldCycleSelected = []
        temporal.detailOldCycleSelectedFilter = []
        temporal.selectedCycleInventory = null
        this.setState({ General: temporal })
        this.getOldCycleInventory()
        await this.handleModalOpen("showModal2")
    }

    async getInfoProduct() {

        this.ModalLoading.current.showState(true);
        this.setState({ secureTransaction1: true })
        var date1 = formatInputDate(document.getElementById('searchHistoryCycleInvDate1').value)
        var date2 = formatInputDate(document.getElementById('searchHistoryCycleInvDate2').value)
        const data = {
            ItemCode: this.state.General.selectedItem.ItemCode,
            Start: date1,
            End: date2,
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId')
        }


        //AQUI COLOCAR LAS LLAMADAS A LOS DATOS
        //const route = '/invertory/getGeneralHistory/post';
        //const generalHistoryData = await getInformationWithData('/invertory/getGeneralHistory/post', data)
        const pickList = await getInformationWithData('/pickList/history/getByItemCode', data)
        //const transfer = await getInformationWithData('/transfer/history/getByItemCode', data)
        const purchase = await getInformationWithData('/purchase/history/getByItemCode', data)
        const adjust = await getInformationWithData('/adjustment/history/getByItemCode', data)
        //const InventStart = await getInformationWithData('/Items/getQuantityHistory', data2)
        //const InventEnd = await getInformationWithData('/Items/getQuantityHistory', data3)

        if (pickList.status.code === 1 && purchase.status.code === 1 && adjust.status.code === 1) {
           
            const categories = this.state.General
            categories.optionsSelect1 = [{ value: 'All', label: 'All' }]
            this.setState({ General: categories })
            await this.consolidateTable(pickList.data, purchase.data, adjust.data/*, generalHistoryData.data*/)
        }
        this.setState({ secureTransaction1: false })
        this.ModalLoading.current.showState(false);
    }

    async DeleteRepeatBins(data, information) {
        const result = await getInformationWithData('/inventory/getDeletedBins/post', data)
        if (result.status.code === 1) {

            for (let it of result.data) {
                for (let a = information.length - 1; a >= 0; a--) {
                    if (information[a].BIN === it.BIN && CompareDates(formatInputDateQuery(information[a].fech), formatInputDateQuery(it.date))) {
                        information.splice(a, 1)
                    }
                }
            }

            return information
        } else {
            return []
        }
    }
    getDataMayorInventory(data, type) {
        var information = []
        for (const it of data) {
            const index = information.findIndex(element => {
                return String(element.BIN).toLowerCase() === it.BIN.toLowerCase()
            })
            if (index === -1) {
                if (type === 'Start') {
                    it.Type = 'Inventory Start'
                } else {
                    it.Type = 'Inventory End'
                }
                information.push(it)
            }
        }


        return information
    }



    async consolidateTable(outbounds, purchase, adjusts/*, generalHistory*/) {


        var InfoArray = []
       
        for (const row of outbounds) {
            const structure = {
                Type: '',
                NoOrder: '',
                Description: '',
                BIN: '',
                BIN2: '',
                QuantityOrder: '',
                QuantityShipped: '',
                User: '',
                Date: '',
                CustomerName: '',
                CustomerNo: ''
            }

            structure.CustomerName = row.Cliente
            structure.CustomerNo = row.NoCliente
            structure.Type = row.Type
            structure.NoOrder = row.OrdenNo
            structure.Description = row.description
            structure.BIN = row.BIN
            structure.BIN2 = null
            structure.QuantityOrder = row.QuantityOrder
            structure.QuantityShipped = row.QuantityShipped
            structure.User = row.username

            if (FormatQueryReturnDate(row.Date).split("/").length === 3) {
                structure.Date = FormatQueryReturnDate(row.Date)
            } else {
                structure.Date = DateFormatMAS90(row.Date)
            }


            InfoArray.push(structure)

        }

        /*

        for (const row of transfers) {
            const structure = {
                Type: '',
                NoOrder: '',
                Description: '',
                BIN: '',
                BIN2: '',
                QuantityOrder: '',
                QuantityShipped: '',
                User: '',
                Date: ''
            }
            structure.Type = row.Type
            structure.Description = row.description
            structure.NoOrder = null
            structure.BIN = row.BINSalida
            structure.BIN2 = row.BINEntrada
            structure.QuantityOrder = 0
            structure.QuantityShipped = row.Quantity
            structure.User = row.username
            structure.Date = FormatQueryReturnDate(row.Date)
            InfoArray.push(structure)

        }
        */
        for (const row of purchase) {
            const structure = {
                Type: '',
                NoOrder: '',
                Description: '',
                BIN: '',
                BIN2: '',
                QuantityOrder: '',
                QuantityShipped: '',
                User: '',
                Date: '',
                CustomerName: '',
                CustomerNo: ''
            }
            structure.Type = row.Type
            structure.CustomerNo = row.VendorNo
            structure.CustomerName = row.VendorName
            structure.NoOrder = row.OrdenNo
            structure.BIN = row.BIN
            structure.Description = row.description
            structure.BIN2 = null
            structure.QuantityOrder = row.Quantity
            structure.QuantityShipped = null
            structure.User = row.username

            if (FormatQueryReturnDate(row.Date).split("/").length === 3) {
                structure.Date = FormatQueryReturnDate(row.Date)
            } else {
                structure.Date = DateFormatMAS90(row.Date)
            }
            InfoArray.push(structure)

        }

        for (const row of adjusts) {
            const structure = {
                Type: '',
                NoOrder: '',
                BIN: '',
                BIN2: '',
                QuantityOrder: '',
                QuantityShipped: '',
                User: '',
                Date: ''
            }
            structure.Type = row.Type
            structure.NoOrder = null
            structure.BIN = row.BIN
            structure.BIN2 = null
            structure.QuantityOrder = row.OldQuantity
            structure.QuantityShipped = row.NewQuantity
            structure.User = row.username
            structure.Description = row.Comment

            if (FormatQueryReturnDate(row.Date).split("/").length === 3) {
                structure.Date = FormatQueryReturnDate(row.Date)
            } else {
                structure.Date = DateFormatMAS90(row.Date)
            }
            InfoArray.push(structure)

        }



        var n = await OrderArrayByDate(InfoArray)

        var actualTypes = this.state.General.optionsSelect1
        for (const row of n) {
            const ind = getIndexElement(actualTypes, "value", row.Type)
            if (ind === -1) {
                const element = {
                    value: row.Type,
                    label: row.Type
                }
                actualTypes.push(element)
            }
        }


        const temporal = this.state.General
        temporal.generalHistory = n
        temporal.generalHistoryFilter = n
        temporal.optionsSelect1 = actualTypes
        this.setState({ General: temporal })
    }


    async getGeneralHistory(itemCode) {
        const tempo = this.state.General
        tempo.outBounds = []
        this.setState({ General: tempo })
        this.handleModalOpen("showModal3")
        this.displaySnipper('snipperDetail', '')
        //this.setState({secureTransaction1:true})
        const temporal = this.state.General
        temporal.selectedItem = itemCode
        temporal.generalHistory = []
        temporal.generalHistoryFilter = []
        const data = {
            ItemCode: itemCode.ItemCode,
            company: getValueCookie('Company'),
            Date: FormatQueryReturnDate(this.state.cycleInventoryStorage.Header.startDate)
        }

        const val = await getInformationWithData('/pickList/history/getOutBound', data)
        const val2 = await getInformationWithData('/invoice/history/getCurrentInvoice', data)
        //const val2 = await getInformationWithData('/purchase/history/getFutureByItemCode', data)
        if (val.status.code === 1 && val2.status.code === 1) {
            var arr = []
            for (const row of val.data) {
                const element = {
                    Type: row.Type,
                    Number: row.Num,
                    BIN: row.BIN,
                    QuantityOrdered: row.QuantityOrdered,
                    QuantityShipped: row.QuantityShipped,
                    username: row.username,
                    date: row.CreationDate
                }
                if (FormatQueryReturnDate(row.CreationDate).split("/").length === 3) {
                    element.date = FormatQueryReturnDate(row.CreationDate)
                } else {
                    element.date = DateFormatMAS90(row.CreationDate)
                }



                arr.push(element)
            }

            for (const row of val2.data) {
                const element = {
                    Type: row.Type,
                    Number: row.InvoiceNo,
                    BIN: row.BIN,
                    QuantityOrdered: row.QuantityOrdered,
                    QuantityShipped: row.QuantityShipped,
                    username: row.SalespersonNo,
                    date: row.InvoiceDate
                }
                if (FormatQueryReturnDate(row.InvoiceDate).split("/").length === 3) {
                    element.date = FormatQueryReturnDate(row.InvoiceDate)
                } else {
                    element.date = DateFormatMAS90(row.InvoiceDate)
                }


                arr.push(element)
            }

            temporal.outBounds = arr
            this.displaySnipper('snipperDetail', 'none')
        }

        /* if (val2.status.code === 1) {
             temporal.purchaseOrders = val2.data
 
         }
         */

        this.setState({ General: temporal })
        //this.setState({secureTransaction1:false})

    }

    async updateSystemQuantity(item) {

        this.setState({ secureTransaction: true })
        Swal.fire({
            title: 'For this Action you need a User and  Password Admin. Please enter it to continue:',
            html: `<input type="text" id="loginChangeQuantity" class="swal2-input" placeholder="Username">
  <input type="password" id="passwordChangeQuantity" class="swal2-input" placeholder="Password">`,
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const login = Swal.getPopup().querySelector('#loginChangeQuantity').value
                const password = Swal.getPopup().querySelector('#passwordChangeQuantity').value
                if (!login || !password) {
                    Swal.showValidationMessage(`Please enter login and password`)
                } else if (login === "diego.perez" && password === "Diego1560") {
                    automaticCloseAlert('correct', 'The Quantity has been updated!')
                } else {
                    automaticCloseAlert('incorrect', 'Username or password are invalid!')
                }

            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        this.setState({ secureTransaction: false })
    }

    async getCheckedItems() {
        const data = {
            id: this.state.cycleInventoryStorage.Header.id,
            idcompany: getValueCookie('CompanyId'),
            company: getValueCookie('Company')
        }
        const route = '/inventory/cycledetail/post';
        const datos = await getInformationWithData(route, data)
        return datos.data
    }

    generateInfo() {

        let proccessInfo = JSON.parse(JSON.stringify(this.state.cycleInventoryStorage.DetailFilter))
        const headerKeys = ['ItemCode', 'productLine', 'Description', 'realQuantity', 'systemQuantity', 'difference', 'countBy', 'date', 'status', 'comentary']
        for (const item of proccessInfo) {
            for (const head of headerKeys) {
                if (head !== "status") {
                    if (item[head] === null || item[head] === "null") {
                        item[head] = ""
                    } else if (head === "date") {
                        item[head] = FormatQueryReturnDate(item[head])
                    }
                } else {
                    if (item[head] === 0) {
                        item[head] = "Not checked"
                    } else {
                        item[head] = "Checked"
                    }
                }

            }
        }

        var info = getDataSet(proccessInfo,
            ['Item Code', 'Product Line', 'Description', 'Quantity', 'System Quantity', 'Difference', 'Counted By', 'Date', 'Status', 'Comments'],
            headerKeys)
        //this.setState({dataset:info})
        return info
    }

    async addComentary(item) {
        this.disableTransaction()
        var comm = ""
        if (item.comentary) {
            comm = item.comentary
        }


        Swal.fire({
            title: 'Product: ' + item.ItemCode + ' \nBIN: ' + item.BIN,
            html: `<div class='textAreaGeneral textAreaComment'>
                    <textarea id='comentOfCycleInventoryDifference' rows="10" cols="50">${comm}</textarea>
                   </div>
            `,
            backdrop: true,
            showCancelButton: true,
            confirmButtonText: 'Save Commentary',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const coment = Swal.getPopup().querySelector('#comentOfCycleInventoryDifference').value
                const temporal = this.state.cycleInventoryStorage
                const index = temporal.Detail.indexOf(item)
                if (index !== -1) {
                    temporal.Detail[index].comentary = coment
                    const response = await create_Delete_Update_Information('/invertory/updateDetailCycle/post', temporal.Detail[index])
                    if (response.status.code === 1) {
                        automaticCloseAlert('correct', 'The comment was save!')
                        await this.setState({ cycleInventoryStorage: temporal })
                    } else {
                        automaticCloseAlert('incorrect', 'The comment was not save')
                    }
                }

            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        await this.enableTransaction()
    }

    onChangeQuantity = (item, e) => {
        const temp = this.state.cycleInventoryStorage
        const index = temp.Detail.indexOf(item)
        if (index !== -1) {
            temp.Detail[index].realQuantity = Number(e.target.value)
        }
    }

    addDifferenceSymbol(diff) {
        if (diff < 0) {
            return ""
        } else {
            return "+"
        }
    }
    RemainingDays(date) {
        var actual = getDateYearMonthDayDash()
        var start = FormatQueryReturnDateWithDash(date)
        var date3 = new Date(start)
        var date4 = new Date(actual)
        var date5 = new Date(start)
        var date6 = new Date(actual)
        var difference = Math.abs(date4 - date3);

        var preliminarDays = difference / (1000 * 3600 * 24)
        var inhDays = 0
        while (date4 <= date3) {
            if (date4.getDay() === 0 || date4.getDay() === 6) {
                inhDays++
            }
            date4 = new Date(date4.getTime() + 86400000)
        }

        if (date6 > date5) {
            return ((preliminarDays - inhDays) * -1).toFixed(0)
        } else {

            return (preliminarDays - inhDays).toFixed(0)
        }


    }

    onChangeFilterHistory = (e) => {
        if (e.value !== 'All') {
            var DetailFilter = this.state.General.generalHistory.filter((item) => {
                if (item.Type.toString().toLowerCase().includes(e.value.toLowerCase())) {
                    return item
                } else {
                    return null
                }
            })
            const temporal = this.state.General
            temporal.generalHistoryFilter = DetailFilter
            this.setState({ General: temporal })
        } else {
            const temporal = this.state.General
            temporal.generalHistoryFilter = temporal.generalHistory
            this.setState({ General: temporal })
        }

    }

    generateExcelDataForHistory() {
        const headers = ["Type", "No Order", "Customer No", "Customer Name", "BIN", "New BIN", `Quantity Order/Old Quantity`, `Quantity Shipped/New Quantity`, "Difference", "Description", "Username", "Date"]
        const keys = ["Type", "NoOrder", "CustomerNo", "CustomerName", "BIN", "BIN2", "QuantityOrder", "QuantityShipped", "difference", "Description", "User", "Date"]
        var copyOfHistory = this.state.General.generalHistoryFilter
        for (const row of copyOfHistory) {

            if (row.Type === "Purchase") {
                row.difference = "+" + row.QuantityOrder
            } else if (row.Type === "Ajuste" || row.Type === "Transferencia") {
                row.difference = this.addDifferenceSymbol(Number(row.QuantityShipped) - Number(row.QuantityOrder)) + (Number(row.QuantityShipped) - Number(row.QuantityOrder))
            } else if (row.Type === "Outbounds" || row.Type === "Invoice") {
                row.difference = "-" + row.QuantityShipped
            }

            for (const key of keys) {
                if (row[key] === null || row[key] === undefined) {
                    row[key] = '-'
                }
            }

        }

        var info = getDataSet(copyOfHistory, headers, keys)
        return info
    }

    getKPI() {
        var DetailFilter1 = this.state.cycleInventoryStorage.Detail.filter((item) => {
            if (item.status === 1) {
                return item
            } else {
                return null
            }
        })

        var DetailFilter3 = this.state.cycleInventoryStorage.Detail.filter((item) => {
            if (item.difference !== 0) {
                return item
            } else {
                return null
            }
        })
        var total = 100 - (DetailFilter3.length / DetailFilter1.length)
        return total.toFixed(2)
    }

    displaySnipper(id, state) {
        let snipper = document.getElementById(id)
        if (snipper) {
            snipper.style.display = state
        } else {

        }

    }

    render() {
        return (
            <div className='inventoryCycle'>
                <LoadingWindow ref={this.ModalLoading} />
                <button hidden id='actionatorCycleInventory' onClick={() => this.getLastCycleInventory()}></button>
                <h2 className='display-4 fw-bold inventory-title text-center pb-5'>Cyclical Inventory</h2>
                <div className='col-12'>
                    <div class="card cycle-inventory-card">
                        <div class="card-body">
                            <div className='mb-3'>
                                <div className='row pb-2'>
                                    <div className='col-sm-12'>
                                        <div className='row'>
                                            <div className='col-sm-1'></div>
                                            <div className='col-sm-5 pb-2'>
                                                <button className='btn btn-primary btn-lg w-100' disabled={this.state.General.secureTransaction} hidden={this.state.cycleInventoryStorage.Header.status === 0} onClick={() => this.startNewCycleInventory()}> Start Cyclical Inventory</button>
                                                <button className='btn btn-lg w-100 redButton' disabled={this.state.General.secureTransaction} hidden={this.state.cycleInventoryStorage.Header.status === 1} onClick={() => this.endCycleInventory()}> End Cyclical Inventory</button>
                                            </div>
                                            <div className='col-sm-5 pb-2'><button className='btn btn-lg w-100 greenButton' onClick={() => this.openOlCycleInventory()} > Open Old Cyclical Inventory</button></div>
                                            <div className='col-sm-1'></div>
                                        </div>
                                    </div>
                                </div>
                                <div hidden={this.state.cycleInventoryStorage.Header.status !== 0} className='row pb-2 pt-3'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-sm-1'></div>
                                            <div className='col-sm-10'>
                                                <div className='row align-middle'>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <div className='col-6 TitleText InputTextBolderSimple'>
                                                                <p>Start Date: </p>
                                                            </div>
                                                            <div className='col-6 TitleText'>
                                                                <p>
                                                                    {formatInputDateQuery(this.state.cycleInventoryStorage.Header.startDate)}
                                                                </p>
                                                            </div>
                                                            <div className='col-6 TitleText InputTextBolderSimple'>
                                                                <p>Estimated Number of Days:</p>
                                                            </div>
                                                            <div className='col-6 TitleText'>
                                                                <p>
                                                                    {this.state.cycleInventoryStorage.Header.days}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='row'>
                                                            <div className='col-6 TitleText InputTextBolderSimple'>
                                                                <p>Proposed End Date:</p>
                                                            </div>
                                                            <div className='col-6 TitleText'>
                                                                <p>{formatInputDateQuery(this.state.cycleInventoryStorage.Header.finishDate)}</p>
                                                            </div>
                                                            <div className='col-6 TitleText InputTextBolderSimple'>
                                                                <p>Remaining Days:</p>
                                                            </div>
                                                            <div className='col-6 TitleText'>
                                                                <p>
                                                                    {isNaN(this.RemainingDays(this.state.cycleInventoryStorage.Header.finishDate)) ? '-' : this.RemainingDays(this.state.cycleInventoryStorage.Header.finishDate)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                            <div className='col-sm-1'></div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card cycle-inventory-card mt-5">
                        <div class="card-body text-center">
                            <div className='mb-3'>
                                <div className='pb-5 text-center col-sm-12'>
                                    <div className='col-sm-12 align-content'>
                                        <p className='display-5 pb-3'>Inventory progress</p>
                                        <ProgressBar ref={this.ProgrressBarRef} />
                                        <div className='row pt-5'>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <div className='text-start col-7 TitleText InputTextBolderSimple'>
                                                        <p>
                                                            Total items:
                                                        </p>
                                                    </div>
                                                    <div className='text-start col-5 TitleText'>
                                                        <p >
                                                            {this.state.cycleInventoryStorage.Header.QuantityItems}
                                                        </p>
                                                    </div>
                                                    <div className='text-start col-7 TitleText InputTextBolderSimple'>
                                                        <p>
                                                            {"Total " + this.state.cycleInventoryStorage.actualTitleSearch + ":"}
                                                        </p>
                                                    </div>
                                                    <div className='text-start col-5 TitleText'>
                                                        <p>
                                                            {this.state.cycleInventoryStorage.Header.FilterQuantity}
                                                        </p>
                                                    </div>
                                                    <div className='text-start col-7 TitleText InputTextBolderSimple'>
                                                        <p>
                                                            {"KPI :"}
                                                        </p>
                                                    </div>
                                                    <div className='text-start col-5 TitleText'>
                                                        <p>
                                                            {isNaN(this.getKPI()) ? "0%" : this.getKPI() + "%"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 radio-group'>
                                                <div className='row fw-bold'>
                                                    <div className='col-10'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className="form-check py-2">
                                                                    <input className="btn-check textRadioButtonSize" value={"-1"} id="AllItemsCycleInv" onChange={this.valueRadioButton} checked={this.state.General.chekvalue === "-1"} type="radio" name="flexRadioDefault" />
                                                                    <label className="btn btn-outline-primary textRadioButtonSize InputTextBolderSimple" htmlFor="AllItemsCycleInv">
                                                                        All items
                                                                    </label>
                                                                </div>
                                                                <div className="form-check py-2">
                                                                    <input className="btn-check textRadioButtonSize" value={"0"} onChange={this.valueRadioButton} id="NoCheckItemsCycleInv" checked={this.state.General.chekvalue === "0"} type="radio" name="flexRadioDefault" />
                                                                    <label className="btn btn-outline-primary textRadioButtonSize InputTextBolderSimple" htmlFor="NoCheckItemsCycleInv">
                                                                        No Checked Items
                                                                    </label>
                                                                </div>
                                                                <div className="form-check py-2">
                                                                    <input className="btn-check textRadioButtonSize" value={"1"} onChange={this.valueRadioButton} id="CheckItemsCycleInv" checked={this.state.General.chekvalue === "1"} type="radio" name="flexRadioDefault" />
                                                                    <label className="btn btn-outline-primary textRadioButtonSize InputTextBolderSimple" htmlFor="CheckItemsCycleInv">
                                                                        Checked Items
                                                                    </label>
                                                                </div>
                                                                <div className="form-check py-2">
                                                                    <input className="btn-check textRadioButtonSize" value={"2"} onChange={this.valueRadioButton} id="DifferencesCycleInv" checked={this.state.General.chekvalue === "2"} type="radio" name="flexRadioDefault" />
                                                                    <label className="btn btn-outline-primary textRadioButtonSize InputTextBolderSimple" htmlFor="DifferencesCycleInv">
                                                                        Differences
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row py-3'>
                        <div className='col-sm-12 text-center'>
                            <ExcelDocument hidden={this.state.cycleInventoryStorage.Header.status === 1} data={this.generateInfo()} sheetname={"CycleInventoryDetail"} archname={"CYCLICAL INVENTORY NO " + this.state.cycleInventoryStorage.Header.id + " COMPANY " + getValueCookie('Company') + " DATE " + getDateFromReports()} ></ExcelDocument>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 pb-3'>
                            <div className="input-group input-group-lg">
                                <span className="input-group-text search-input"><AiOutlineSearch /></span>
                                <input type="text" autoComplete='off' className="form-control" placeholder='Search by ItemCode, BIN, Description, Category, UPC ...' id='searchCycleInv1' onChange={this.valueSearchBar} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>

                        <div className='col-12 tableFixHead tb-5'>
                            <table className='table align-middle table-hover'>
                                <thead>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark'>Item Code</th>
                                        <th className='bg-dark'>Product Line</th>
                                        <th className='bg-dark'>Description</th>
                                        <th className='bg-dark'>Last Due Date</th>
                                        <th className='bg-dark'>Quantity</th>
                                        <th hidden className='bg-dark'>BIN</th>
                                        <th className='bg-dark'>System Quantity</th>
                                        <th className='bg-dark'>Difference</th>
                                        <th className='bg-dark'>Counted By</th>
                                        <th className='bg-dark'>Status</th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark'></th>
                                        <th hidden className='bg-dark'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cycleInventoryStorage.DetailFilter.map((item, i) => (
                                        <tr key={i}>

                                            <td className='textTableSize'>{item.ItemCode}</td>
                                            <td className='textTableSize'>{item.productLine}</td>
                                            <td className='textTableSize' >{item.Description}</td>
                                            <td className='' >{item.LastDueDate?FormatQueryReturnDate(item.LastDueDate):'Not Available'}</td>
                                            <td>
                                            <div className="input-group input-group-lg flex-nowrap">
                                                <input disabled={item.status === 1} type="number" key={item.realQuantity} defaultValue={item.realQuantity} id={"realQuantityCycleInv_" + item.id} className="form-control text-center inputResize" onChange={(e) => this.onChangeQuantity(item, e)} />
                                            </div>
                                            </td>
                                            <td hidden className=' textTableSize text-center'>{item.BIN}</td>
                                            <td className='textTableSize text-center'>{item.status === 0 ? "-" : item.systemQuantity}</td>
                                            <td className='text-center textTableSize'>{item.status === 0 ? "-" : item.difference}</td>
                                            <td className='text-center textTableSize'>{item.countBy === null ? "-" : item.countBy}</td>
                                            <td className='text-center textTableSize'>{this.textStatus(item.status)}</td>
                                            <td>
                                                    <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.addComentary(item)} disabled={this.state.General.secureTransaction || item.status === 0}><AiOutlineComment /></button>
                                            </td>
                                            <td className='text-center'>
                                                <button type="button" className="btn greenButton btn-lg" disabled={this.state.General.secureTransaction} onClick={() => this.setCycleInventoryDetailInfo(item, "realQuantityCycleInv_" + item.id,)} hidden={item.status === 1}><AiOutlineCheck /></button>
                                                <button type="button" className="btn redButton btn-lg" disabled={this.state.General.secureTransaction} onClick={() => this.updateCycleInventoryDetail(item)} hidden={item.status === 0}>Change</button>
                                            </td>
                                            <td className='text-center'><button disabled={this.state.secureTransaction1} onClick={() => this.getGeneralHistory(item)} type="button" className="btn aquaButton btn-lg"><AiOutlineSearch /></button></td>
                                            <td className='text-center' hidden><button disabled={this.state.General.secureTransaction} onClick={() => this.updateSystemQuantity(item)} type="button" className="btn redButton btn-lg" hidden={item.status === 0}>Update Inventory</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-secondary text-light'>

                                    </tr>
                                </tfoot>

                            </table>
                        </div>

                    </div>


                </div>

                <ModalOrders title={'Old Cyclical Inventory'} show={this.state.General.showModal2} close={(modal = "showModal2") => this.handleModalClose(modal)}>
                    <div className='row pt-3'>
                        <div className='col-12 display-5 pb-3'>
                            <p >Select an old Cyclical Inventory to see they detail:</p>
                        </div>
                        <div className='col-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead className='thead'>
                                    <tr className='bg-dark text-light'>
                                        <th className='text-center bg-dark'>Id</th>
                                        <th className='text-center bg-dark'>Start Date</th>
                                        <th className='text-center bg-dark'>Estimated Date to finish</th>
                                        <th className='text-center bg-dark'>Real Finish Date</th>
                                        <th className='text-center bg-dark'>Days</th>
                                        <th className='text-center bg-dark'>Create By</th>
                                        <th className='text-center bg-dark'>Close By</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        this.state.General.oldCycleInventory.map((item, i) => (
                                            <tr className={this.state.General.selectedCycleInventory === item ? 'bg-warning text-center' : 'text-center'} onClick={() => this.getOldDetailCycleInventory(item)} key={i}>
                                                <td className='textTableSize'>{item.id}</td>
                                                <td className='textTableSize'>{item.startDate2}</td>
                                                <td className='textTableSize'>{item.finishDate2}</td>
                                                <td className='textTableSize'>{item.realFinishDate2}</td>
                                                <td className='textTableSize'>{item.realQuantityDays}</td>
                                                <td className='textTableSize'>{item.createBy}</td>
                                                <td className='textTableSize'>{item.closeBy}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='bg-secondary text-light'>

                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                        <div className='col-12 display-5 '>
                            <p >Detail:</p>
                        </div>
                        <div className='col-12 pt-3 pb-3'>
                            <div className="input-group input-group-lg">
                                <span className="input-group-text"><AiOutlineSearch /></span>
                                <input type="text" className="form-control" placeholder='Search by BIN, Description, Date...' id='searchOldCycleInv' onChange={this.searchOlderCycleInventoryDetail} />
                            </div>
                        </div>
                        <div className='col-12 pt-3 pb-3 text-center'>
                            <div className='row h4'>
                                <div className='col correctCount2'>Exact difference</div>
                                <div className='col negativeCount2'>Negative difference</div>
                                <div className='col positiveCount2'>Positive difference</div>
                                
                            </div>
                        </div>
                        <div className='col-12'>{/*Here we need specific information*/}</div>
                        <div className='col-12 tableFixHead tb-5'>
                            <table className='table table-hover'>
                                <thead>
                                    <tr className='bg-dark text-light text-center'>
                                        <th className='bg-dark'>Item Code</th>
                                        <th className='bg-dark'>Description</th>
                                        <th className='bg-dark'>Quantity</th>
                                        <th className='bg-dark'>BIN</th>
                                        <th className='bg-dark'>System Quantity</th>
                                        <th className='bg-dark'>Difference</th>
                                        <th className='bg-dark'>Counted By</th>
                                        <th className='bg-dark'>Status</th>
                                        <th className='bg-dark'>Comments</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.General.detailOldCycleSelectedFilter.map((item, i) => (
                                        <tr className={item.status === 0 ? 'notcounted' : item.difference < 0 ? 'negativeCount' : item.difference === 0 ? 'correctCount' : 'positiveCount'} key={i}>

                                            <td className='text-start textTableSize'>{item.ItemCode}</td>
                                            <td className='text-start textTableSize'>{item.Description}</td>
                                            <td className='text-center textTableSize'>{item.status === 0 ? "-" : item.realQuantity}</td>
                                            <td className='text-center textTableSize'>{(item.BIN&&item.BIN!=='null')?item.BIN:"-"}</td>
                                            <td className='text-center textTableSize'>{item.status === 0 ? "-" : item.systemQuantity}</td>
                                            <td className='text-center textTableSize'>{item.status === 0 ? "-" : item.difference}</td>
                                            <td className='text-center textTableSize'>{item.countBy === null ? "-" : item.countBy}</td>
                                            <td className='text-center textTableSize'>{this.textStatus(item.status)}</td>
                                            <td className='text-center textTableSize'>{item.comentary === 'null' ? '-' : item.comentary}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-secondary text-light'>

                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                </ModalOrders>
                <ModalOrders title={'Product Detail'} show={this.state.General.showModal3} close={(modal = "showModal3") => this.handleModalClose(modal)}>

                    <div className='row text-center pt-3'>
                        <div className='col-1'></div>
                        <div className='col-5'>
                            <div className='row fs-3'>
                                <div className='col-12 text-start'>
                                    <span>
                                        <p>Product:</p>
                                        <span className='badge bg-primary'>{this.state.General.selectedItem.ItemCode}</span>
                                    </span>
                                </div>
                                <div className='col-12 text-start'>
                                </div>
                            </div>
                            <div className='row fs-3'>
                                <div className='col-12 justifyText'>
                                    <p>{this.state.General.selectedItem.Description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-5 text-center'>
                            <img className=' pt-4 detailIMG' src='/assets/notavailable.png' alt='Company' />
                        </div>
                        <div className='col-1'></div>
                    </div>


                    <div className='row text-start pt-5'>
                        <div className='row'>
                            <div className='col-12 fs-5 d-inline'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                                            <div>
                                                Current Orders who maybe affect the current physical inventory
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="spinner-border text-danger" id='snipperDetail' role="status"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead>
                                    <tr className='bg-dark text-light text-center'>
                                        <th className='bg-dark'>Type</th>
                                        <th className='bg-dark'>Number</th>
                                        <th hidden={getValueCookie('CompanyId') === "3"} className='bg-dark'>BIN</th>
                                        <th className='bg-dark'>Quantity Order</th>
                                        <th className='bg-dark'>Quantity Shipped</th>
                                        <th className='bg-dark'>Username <br /> Sales Person</th>
                                        <th className='bg-dark'>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.General.outBounds.map((item, i) => (
                                        <tr className='text-center' key={i}>
                                            <td className='text-center'>{item.Type}</td>
                                            <td className='text-start'>{item.Number}</td>
                                            <td hidden={getValueCookie('CompanyId') === "3"}>{item.BIN}</td>
                                            <td>{item.QuantityOrdered}</td>
                                            <td>{item.QuantityShipped}</td>
                                            <td>{item.username}</td>
                                            <td>{item.date}</td>
                                        </tr>
                                    ))

                                    }
                                    {/*this.state.General.purchaseOrders.map((item, i) => (
                                        <tr className='text-center' key={i}>
                                            <td className='text-center'>{item.Type}</td>
                                            <td className='text-start'>{item.OrdenNo}</td>
                                            <td>{item.BIN}</td>
                                            <td>{item.Quantity}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                        */
                                    }

                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='bg-secondary text-light'>

                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-5'>
                            <div className="input-group input-group-lg">
                                <span className="input-group-text"><AiFillCalendar /></span>
                                <input type="date" className="form-control" id='searchHistoryCycleInvDate1' />
                            </div>

                        </div>
                        <div className='col-5'>
                            <div className="input-group input-group-lg">
                                <span className="input-group-text"><AiFillCalendar /></span>
                                <input type="date" className="form-control" id='searchHistoryCycleInvDate2' />
                            </div>
                        </div>
                        <div className='col-1'></div>
                    </div>
                    <div className='row text-center pt-3'>
                        <div className='col-5'></div>
                        <div className='col-2'>
                            <button disabled={this.state.secureTransaction1} className='btn blueButton btn-lg' onClick={() => this.getInfoProduct()}>Search</button>
                        </div>
                        <div className='col-5'></div>

                    </div>
                    <div className='row fs-3'>
                        <div className='col-12 text-start'>
                            <p>Search Result:</p>
                        </div>

                    </div>
                    <div className='row fs-4 pb-3'>
                        <div className='col-6 text-start'>
                            <Select defaultValue={this.state.General.optionsSelect1[0]} options={this.state.General.optionsSelect1} onChange={this.onChangeFilterHistory} />
                        </div>
                        <div className='col-6'>
                            <ExcelDocument hidden={this.state.General.generalHistoryFilter.length === 0} data={this.generateExcelDataForHistory()} sheetname={"History ITEM " + this.state.General.selectedItem.ItemCode} archname={"Hystory Product " + this.state.General.selectedItem.ItemCode + " COMPANY " + getValueCookie('Company') + " DATE " + getDateFromReports()} ></ExcelDocument>

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead>
                                    <tr className='bg-dark text-light text-center'>
                                        <th className='bg-dark'>Type</th>
                                        <th className='bg-dark'>No Order</th>
                                        <th hidden={getValueCookie('CompanyId') === '2'} className='bg-dark'>Customer No</th>
                                        <th hidden={getValueCookie('CompanyId') === '2'} className='bg-dark'>Customer Name</th>
                                        <th className='bg-dark'>BIN</th>
                                        <th hidden={getValueCookie('CompanyId') === '3'} className='bg-dark'>New BIN</th>
                                        <th className='bg-dark'>Quantity Order/<br />Old Quantity</th>
                                        <th className='bg-dark'>Quantity Shipped/<br />New Quantity</th>
                                        <th className='bg-dark'>Balance</th>
                                        <th className='bg-dark'>Description</th>
                                        <th className='bg-dark'>Username</th>
                                        <th className='bg-dark'>Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.General.generalHistoryFilter.map((item, i) => (
                                        <tr className={'text-center ' + ((item.Type === 'Inventory Start' || item.Type === 'Inventory End') ? (item.Type === 'Inventory Start' ? 'correctCount' : 'positiveCount') : '')} key={i}>
                                            <td className='text-start'>{item.Type}</td>
                                            <td className='text-start'>{item.NoOrder}</td>
                                            <td hidden={getValueCookie('CompanyId') === '2'} className='text-start'>{item.CustomerNo}</td>
                                            <td hidden={getValueCookie('CompanyId') === '2'} className='text-start'>{item.CustomerName}</td>
                                            <td>{item.BIN}</td>
                                            <td hidden={getValueCookie('CompanyId') === '3'} >{item.BIN2}</td>
                                            <td>{item.QuantityOrder}</td>
                                            <td>{item.QuantityShipped}</td>
                                            <td className='text-center'>{(item.Type === "Transferencia" || item.Type === "Ajuste") ? this.addDifferenceSymbol(Number(item.QuantityShipped) - Number(item.QuantityOrder)) + (Number(item.QuantityShipped) - Number(item.QuantityOrder)) : ((item.Type === 'Outbound' || item.Type === 'Purchase' || item.Type === 'Invoice') ? ((item.Type === 'Outbound' || item.Type === 'Invoice') ? "-" + item.QuantityShipped : "+" + item.QuantityOrder) : '-')}</td>
                                            <td className='text-start'>{item.Description}</td>
                                            <td>{item.User}</td>
                                            <td>{item.Date}</td>
                                        </tr>
                                    ))

                                    }

                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='bg-secondary text-light'>

                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}