import React, { Component } from 'react'
import { BsArrowRepeat, BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import LoadingWindow from '../../../components/LoadingWindow';
import { getValueCookie } from '../../../services/cookieService';
import { getIndexElement } from '../../../functions/searchInObject';
import { AveragePerDay, InventoryProductLine } from './Class';
import { DateFormatMAS90, formatInputDateQuerytoInput, FormatQueryReturnDate, getDateFromReports, getDateYearMonthDayDash, ReplaceDashForLateralBar } from '../../../functions/dateFormat';
import { ItemDesc, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDataSet } from '../../../functions/generateDataSetExcel';


export default class ActualInventoryReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        AllKPI: [],
        FilterKPI: [],
        AllErrorItems: [],
        FilterAllErrorItems: [],
        AllNotEqualDifferences: [],
        FilterAllNotEqualDifferences: [],
        totals: {
            TotalItems: 0,
            CountItems: 0,
            OkItems: 0,
            ErrorItems: 0,
            percentageAdvance: 0,
            BinaryAccuracy: 0,
            TotalCost: 0,
            TotalValued: 0,
            ValuedAccuracy: 0,
            KPI: 0
        },
        currentLineProd: {
            ProLine: {},
            Body: [],
            BodyFilter: []
        },
        showModal1: false,
        order: false,
        AverageDays: []
    }
    OrderInformation(array, key, asc) {
        const order = OrderArray(array, key, asc)
        this.setState({ FilterKPI: order, order: !asc })

    }
    Order(array, key, asc) {
        const order = OrderArray(array, key, asc)
        return order
    }
    OrderSpecificInformation(array, key, asc) {
        const temporal = this.state.currentLineProd
        const order = OrderArray(array, key, asc)
        temporal.BodyFilter = order
        this.setState({ currentLineProd: temporal, order: !asc })

    }
    OrderAverageDays(array, key, asc) {

        const order = OrderArray(array, key, asc)
        this.setState({ AverageDays: order, order: !asc })

    }
    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    GeneralOrder(key, asc, name) {
        const temporal = this.state[name]
        const Order = OrderArray(temporal, key, asc)
        this.setState({ [name]: Order, order: !asc })
    }

    async getDetailActualInventory() {
        this.ModalLoading.current.showState(true);
        const data = {
            idcompany: getValueCookie('CompanyId'),
            company: getValueCookie('Company'),
            ItemCode: '',

        }
        const datos = await getInformationWithData('/inventory/lastCycle/post', data)
        //const adjust= await getInformationWithData('/adjustment/history/getByItemCode',)
        if (datos.status.code === 1) {

            if (datos.data.length > 0) {
                data.id = datos.data[0].id
                data.Start = ReplaceDashForLateralBar(formatInputDateQuerytoInput(datos.data[0].startDate))
                data.End = ReplaceDashForLateralBar(getDateYearMonthDayDash())

                const allProducts = await getInformationWithData('/inventory/allProductsCycle/post', data)
                allProducts.data=await ItemDesc(allProducts.data,'Description','ItemCode')
                const checkedProducts = await getInformationWithData('/inventory/cycledetail/post', data)
                checkedProducts.data=await ItemDesc(checkedProducts.data,'Description','ItemCode')
                const resultSald = await getInformationWithData('/adjustment/history/getByItemCode', data)

                if (allProducts.status.code === 1 && checkedProducts.status.code === 1) {
                    const totalProducts = allProducts.data.length
                    let listProductLine = []
                    let wrongItems = []
                    let wrongDifferences = []
                    let notCountedItems = []
                    let DaysWithCost = []
                    for (const item of allProducts.data) {
                        const index = getIndexElement(listProductLine, 'Name', item.productLine)

                        if (index === -1) {
                            let prodLine = new InventoryProductLine(item.productLine)
                            const index2 = getIndexElement(checkedProducts.data, 'ItemCode', item.ItemCode)
                            const index3 = getIndexElement(resultSald.data, 'ItemCode', item.ItemCode)
                            if (index3 !== -1) {
                                const tempIndex = getIndexElement(DaysWithCost, 'Date', DateFormatMAS90(resultSald.data[index3].Date))
                                if (tempIndex !== -1) {
                                    DaysWithCost[tempIndex].addCost(resultSald.data[index3].ExtendedCost)
                                } else {
                                    const newCost = new AveragePerDay(resultSald.data[index3].Date, resultSald.data[index3].ExtendedCost)
                                    DaysWithCost.push(newCost)
                                }
                            }
                            if (index2 === -1) {
                                item.TotalCostMAS90 = index3 !== -1 ? resultSald.data[index3].ExtendedCost : 0.00
                                item.DifferenceMAS90 = index3 !== -1 ? resultSald.data[index3].NewQuantity : 0
                                item.TotalCost = 0
                                item.TotalValued = 0

                                if (item.difference !== item.DifferenceMAS90) {
                                    wrongDifferences.push(item)
                                }

                                prodLine.addItem()
                                prodLine.calculateBinaryAccuracy()
                                prodLine.calculateAdvance(totalProducts)
                                prodLine.calculateTotalCost(item.TotalCostMAS90)
                                prodLine.ListProducts.push(item)
                                prodLine.calculateTotalValued()
                                prodLine.calculateValuedAccuracy()
                                prodLine.calculateKPI()
                                listProductLine.push(prodLine)
                                notCountedItems.push(item)
                            } else {
                                const itemTemporal = checkedProducts.data[index2]
                                itemTemporal.TotalCostMAS90 = index3 !== -1 ? resultSald.data[index3].ExtendedCost : 0.00
                                itemTemporal.DifferenceMAS90 = index3 !== -1 ? resultSald.data[index3].NewQuantity : 0
                                itemTemporal.TotalCost = itemTemporal.difference * itemTemporal.LastTotalUnitCost
                                itemTemporal.TotalValued = itemTemporal.LastTotalUnitCost * itemTemporal.realQuantity
                                prodLine.LastDate = FormatQueryReturnDate(itemTemporal.date)

                                if (itemTemporal.difference !== itemTemporal.DifferenceMAS90) {
                                    wrongDifferences.push(itemTemporal)
                                }

                                prodLine.ListProducts.push(itemTemporal)
                                if (itemTemporal.difference === 0) {
                                    prodLine.addItem()
                                    prodLine.addCountItem()
                                    prodLine.addOkItem()
                                    prodLine.calculateBinaryAccuracy()
                                    prodLine.calculateAdvance(totalProducts)
                                    prodLine.calculateTotalCost(itemTemporal.TotalCostMAS90)
                                    prodLine.calculateTotalValued()
                                    prodLine.calculateValuedAccuracy()
                                    prodLine.calculateKPI()
                                    listProductLine.push(prodLine)
                                } else {
                                    prodLine.addItem()
                                    prodLine.addCountItem()
                                    prodLine.addWrongItem()
                                    prodLine.calculateBinaryAccuracy()
                                    prodLine.calculateAdvance(totalProducts)
                                    prodLine.calculateTotalCost(itemTemporal.TotalCostMAS90)
                                    prodLine.calculateTotalValued()
                                    prodLine.calculateValuedAccuracy()
                                    prodLine.calculateKPI()
                                    listProductLine.push(prodLine)
                                    wrongItems.push(itemTemporal)

                                }
                            }
                        } else {
                            const index2 = getIndexElement(checkedProducts.data, 'ItemCode', item.ItemCode)
                            const index3 = getIndexElement(resultSald.data, 'ItemCode', item.ItemCode)
                            if (index3 !== -1) {
                                const tempIndex = getIndexElement(DaysWithCost, 'Date', DateFormatMAS90(resultSald.data[index3].Date))
                                if (tempIndex !== -1) {
                                    DaysWithCost[tempIndex].addCost(resultSald.data[index3].ExtendedCost)
                                } else {
                                    const newCost = new AveragePerDay(resultSald.data[index3].Date, resultSald.data[index3].ExtendedCost)
                                    DaysWithCost.push(newCost)
                                }
                            }
                            if (index2 === -1) {
                                item.TotalCostMAS90 = index3 !== -1 ? resultSald.data[index3].ExtendedCost : 0.00
                                item.DifferenceMAS90 = index3 !== -1 ? resultSald.data[index3].NewQuantity : 0
                                item.TotalCost = 0
                                item.TotalValued = 0

                                if (item.difference !== item.DifferenceMAS90) {
                                    wrongDifferences.push(item)
                                }

                                listProductLine[index].ListProducts.push(item)
                                listProductLine[index].addItem()
                                listProductLine[index].calculateBinaryAccuracy()
                                listProductLine[index].calculateValuedAccuracy()
                                listProductLine[index].calculateKPI()
                                listProductLine[index].calculateAdvance(totalProducts)
                                listProductLine[index].calculateTotalValued()
                                notCountedItems.push(item)
                                if (index3 !== -1) {
                                    listProductLine[index].calculateTotalCost(item.TotalCostMAS90)
                                }
                            } else {
                                const itemTemporal = checkedProducts.data[index2]
                                itemTemporal.TotalCostMAS90 = index3 !== -1 ? resultSald.data[index3].ExtendedCost : 0.00
                                itemTemporal.DifferenceMAS90 = index3 !== -1 ? resultSald.data[index3].NewQuantity : 0
                                itemTemporal.TotalCost = itemTemporal.difference * itemTemporal.LastTotalUnitCost
                                itemTemporal.TotalValued = itemTemporal.LastTotalUnitCost * itemTemporal.realQuantity
                                listProductLine[index].ListProducts.push(itemTemporal)

                                if (itemTemporal.difference !== itemTemporal.DifferenceMAS90) {
                                    wrongDifferences.push(itemTemporal)
                                }


                                if (listProductLine[index].LastDate === 'Not Counting Yet') {
                                    listProductLine[index].LastDate = FormatQueryReturnDate(itemTemporal.date)
                                } else {
                                    listProductLine[index].LastDate = listProductLine[index].LastDate < FormatQueryReturnDate(itemTemporal.date) ? FormatQueryReturnDate(itemTemporal.date) : listProductLine[index].LastDate
                                }
                                if (itemTemporal.difference === 0) {
                                    listProductLine[index].addItem()
                                    listProductLine[index].addCountItem()
                                    listProductLine[index].addOkItem()
                                    listProductLine[index].calculateBinaryAccuracy()
                                    listProductLine[index].calculateValuedAccuracy()
                                    listProductLine[index].calculateKPI()
                                    listProductLine[index].calculateAdvance(totalProducts)
                                    listProductLine[index].calculateTotalValued()


                                } else {
                                    listProductLine[index].addItem()
                                    listProductLine[index].addCountItem()
                                    listProductLine[index].addWrongItem()
                                    listProductLine[index].calculateBinaryAccuracy()
                                    listProductLine[index].calculateValuedAccuracy()
                                    listProductLine[index].calculateKPI()
                                    listProductLine[index].calculateAdvance(totalProducts)
                                    listProductLine[index].calculateTotalValued(0)
                                    wrongItems.push(itemTemporal)

                                }
                                if (index3 !== -1) {
                                    listProductLine[index].calculateTotalCost(itemTemporal.TotalCostMAS90)
                                }
                            }
                        }
                    }
                    console.log(notCountedItems)
                    listProductLine = this.Order(listProductLine, 'LastDate', true)
                    DaysWithCost = this.Order(DaysWithCost, 'Date', true)
                    listProductLine = this.calculateTotals(listProductLine)
                    this.setState({
                        AllKPI: listProductLine,
                        FilterKPI: listProductLine,
                        AverageDays: DaysWithCost,
                        AllErrorItems: wrongItems,
                        FilterAllErrorItems: wrongItems,
                        AllNotEqualDifferences: wrongDifferences,
                        FilterAllNotEqualDifferences: wrongDifferences
                    })

                }


            } else {
                //NO HAY INFO
            }
        } else {
            //ERROR
        }
        this.ModalLoading.current.showState(false);
    }

    openDetailProductLine(prodLine) {

        const temporal = this.state.currentLineProd
        temporal.Body = prodLine.ListProducts
        temporal.BodyFilter = prodLine.ListProducts
        temporal.ProLine = prodLine
        this.handleModalOpen("showModal1")
    }

    calculateTotals(array) {
        const temporal = this.state.totals
        let TotalItems = 0
        let CountItems = 0
        let OkItems = 0
        let ErrorItems = 0
        let percentageAdvance = 0
        let TotalCost = 0
        let TotalValued = 0


        for (const line of array) {
            TotalItems += line.TotalItem
            CountItems += line.TotalCountItem
            OkItems += line.TotalOKItem
            ErrorItems += line.TotalWrongItem
            percentageAdvance += line.Advance
            TotalCost += line.TotalCost
            TotalValued += line.TotalValued
            line.Accumulated = line.LastDate !== 'Not Counting Yet' ? percentageAdvance : 0
        }
        temporal.TotalItems = TotalItems
        temporal.CountItems = CountItems
        temporal.OkItems = OkItems
        temporal.ErrorItems = ErrorItems
        temporal.percentageAdvance = percentageAdvance
        temporal.TotalCost = TotalCost
        temporal.TotalValued = TotalValued
        let real = CountItems === 0 ? 1 : (ErrorItems / CountItems)
        temporal.BinaryAccuracy = 100 - (real * 100)
        if (temporal.TotalValued !== 0) {
            temporal.ValuedAccuracy = (1 + (temporal.TotalCost / temporal.TotalValued)) * 100
        } else {
            temporal.ValuedAccuracy = 0
        }
        temporal.KPI = (temporal.BinaryAccuracy * 0.3) + (temporal.ValuedAccuracy * 0.7)


        this.setState({ totals: temporal })

        return array
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }
    searchDetailCycleInventory = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.AllKPI
        } else {
            Filter = this.state.AllKPI.filter((detail) => {
                if (this.Contains(detail.Name, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterKPI: Filter })
    }

    searchErrorCycleInventory = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.AllErrorItems
        } else {
            Filter = this.state.AllErrorItems.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue)
                    || this.Contains(detail.productLine, searchValue)
                    || this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterAllErrorItems: Filter })
    }
    searchNotEqualDifferencesCycleInventory = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.AllNotEqualDifferences
        } else {
            Filter = this.state.AllNotEqualDifferences.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue)
                    || this.Contains(detail.productLine, searchValue)
                    || this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterAllNotEqualDifferences: Filter })
    }
    searchCurrentLineProdCycleInventory = e => {
        const searchValue = e.target.value
        const temporal = this.state.currentLineProd

        if (searchValue === '') {
            temporal.BodyFilter = this.state.currentLineProd.Body
        } else {
            temporal.BodyFilter = this.state.currentLineProd.Body.filter((detail) => {
                if (this.Contains(detail.ItemCode, searchValue)
                    || this.Contains(detail.Description, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ currentLineProd: temporal })
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Report Actual KPI Inventory</h2>
                </div>
                <div className='row'>
                    <div className='col-1 pb-3'></div>
                    <div className='col-10 pb-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getDetailActualInventory()} type="button" className="btn blueButton btn-lg">Get Information <BsArrowRepeat /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1 pb-3'></div>
                    <div hidden={this.state.AllKPI.length === 0} className='col-12 pb-2' >
                        <ul className="nav nav-tabs" id="ActualControls" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button id='detailActCycleInv' className="nav-link active" data-bs-toggle="tab" data-bs-target="#detailActCycle" type="button" role="tab" aria-controls="detailActCycle" aria-selected="true">Detail Cycle Inventory</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button id='errorActCycleInv' className="nav-link" data-bs-toggle="tab" data-bs-target="#errorActCycle" type="button" role="tab" aria-controls="errorActCycle" aria-selected="false">All Wrong Items</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button id='diffActItemsInv' className="nav-link" data-bs-toggle="tab" data-bs-target="#diffActItems" type="button" role="tab" aria-controls="diffActItems" aria-selected="false">Not Equal Adjustments</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="allCycleHeader">
                            <div className="tab-pane fade show active" id="detailActCycle" role="tabpanel" aria-labelledby="detailActCycle">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchDetailCycleInventory} className="form-control form-control-lg" placeholder='Search by Product Line' id='searchActCycleInventory' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Actual Cycle Inventory KPI ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Actual Cycle Inventory KPI ' + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterKPI, ['Product Line', 'Date', 'Total Items', 'Actual Count', 'Ok Items', 'Error Items', 'Percentage of Advance', 'Percentage of Accumulated', 'Total Accumulated ($)', 'Total Valued ($)', 'Binary Accuracy', 'Valued Accuracy', 'KPI'], ['Name', 'LastDate', 'TotalItem', 'TotalCountItem', 'TotalOKItem', 'TotalWrongItem', 'Advance', 'Accumulated', 'TotalCost', 'TotalValued', 'BinaryAccuracy', 'ValuedAccuracy', 'KPI'])}

                                        />
                                    </div>
                                    <div className='col-12 pb-2 tableFixHead'>

                                        <table className='table align-middle table-hover'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'Name', this.state.order)}><div className='row'><div className='col-12'>Product Line </div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'LastDate', this.state.order)}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalItem', this.state.order)}><div className='row'><div className='col-12'>Total Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalCountItem', this.state.order)}><div className='row'><div className='col-12'>Actual Count Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalOKItem', this.state.order)}><div className='row'><div className='col-12'>Ok Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalWrongItem', this.state.order)}><div className='row'><div className='col-12'>Error Items</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'Advance', this.state.order)}><div className='row'><div className='col-12'>Percentage of Advance</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'Accumulated', this.state.order)}><div className='row'><div className='col-12'>Percentage of Accumulated</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalCost', this.state.order)}><div className='row'><div className='col-12'>Total Accumulated ($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'TotalValued', this.state.order)}><div className='row'><div className='col-12'>Total Valued ($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'BinaryAccuracy', this.state.order)}><div className='row'><div className='col-12'>Binary Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'ValuedAccuracy', this.state.order)}><div className='row'><div className='col-12'>Valued Accuracy</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderInformation(this.state.FilterKPI, 'KPI', this.state.order)}><div className='row'><div className='col-12'>KPI</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>

                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterKPI.map((proLine, i) => (
                                                        <tr onClick={() => this.openDetailProductLine(proLine)} key={i}>
                                                            <td className='text-start'>{proLine.Name}</td>
                                                            <td className='text-center'>{proLine.LastDate}</td>
                                                            <td className='text-center'>{proLine.TotalItem}</td>
                                                            <td className='text-center'>{proLine.TotalCountItem}</td>
                                                            <td className='text-center'>{proLine.TotalOKItem}</td>
                                                            <td className='text-center'>{proLine.TotalWrongItem}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.Advance) + " %"}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.Accumulated) + " %"}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCost))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalValued))}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.BinaryAccuracy) + " %"}</td>
                                                            <td className='text-center'>{pricesFormat(proLine.ValuedAccuracy) + " %"}</td>
                                                            <td className='text-center inputResize'>{pricesFormat(proLine.KPI) + " %"}</td>

                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className='bg-secondary text-white'>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'>TOTALS</td>
                                                    <td className='text-center'>{this.state.totals.TotalItems}</td>
                                                    <td className='text-center'>{this.state.totals.CountItems}</td>
                                                    <td className='text-center'>{this.state.totals.OkItems}</td>
                                                    <td className='text-center'>{this.state.totals.ErrorItems}</td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'>{pricesFormat(this.state.totals.percentageAdvance) + " %"}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(this.state.totals.TotalCost))}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(this.state.totals.TotalValued))}</td>
                                                    <td className='text-center'>{pricesFormat(this.state.totals.BinaryAccuracy) + " %"}</td>
                                                    <td className='text-center'>{pricesFormat(this.state.totals.ValuedAccuracy) + " %"}</td>
                                                    <td className='text-center'>{pricesFormat(this.state.totals.KPI) + " %"}</td>
                                                </tr>

                                            </tfoot>

                                        </table>

                                    </div>

                                    <div hidden={this.state.AllKPI.length === 0} className='col-12 pb-5 tableFixHead'>

                                        <table className='table align-middle table-hover'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.OrderAverageDays(this.state.AverageDays, 'Date', this.state.order)}><div className='row'><div className='col-12'>Day </div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.OrderAverageDays(this.state.AverageDays, 'Date', this.state.order)}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark'></th>
                                                    <th className='bg-dark' onClick={() => this.OrderAverageDays(this.state.AverageDays, 'TotalCost', this.state.order)}><div className='row'><div className='col-12'>Total Adjust Value($)</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.AverageDays.map((proLine, i) => (
                                                        <tr key={i}>
                                                            <td className='text-center'>{i + 1}</td>
                                                            <td className='text-center'>{proLine.Date}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCost))}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="errorActCycle" role="tabpanel" aria-labelledby="errorActCycle">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchErrorCycleInventory} className="form-control form-control-lg" placeholder='Search by Item Code, Descritpion, Product Line...' id='searchErrorActCycleInventory' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Actual Cycle Inventory All Error Items ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Actual Cycle Inventory All Error Items ' + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterAllErrorItems, ['Item Code', 'Product Line', 'Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode', 'productLine', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'DifferenceMAS90', 'TotalCost', 'TotalCostMAS90', 'TotalValued', 'date', 'countBy', 'comentary'])}

                                        />
                                    </div>
                                    <div hidden={this.state.AllErrorItems.length === 0} className='col-12 pb-2 tableFixHead'>
                                        <table className='table align-middle table-hover'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('ItemCode', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('productLine', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('Description', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('BIN', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('systemQuantity', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('realQuantity', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('difference', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('DifferenceMAS90', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalCost', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Total Cost System</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalCostMAS90', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Total Cost MAS90</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalValued', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('date', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('countBy', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('comentary', this.state.order, 'FilterAllErrorItems')}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterAllErrorItems.map((proLine, i) => (
                                                        <tr className={proLine.difference !== proLine.DifferenceMAS90 ? 'redButton' : ''} key={i}>
                                                            <td className='text-start'>{proLine.ItemCode}</td>
                                                            <td className='text-start'>{proLine.productLine}</td>
                                                            <td className='text-start'>{proLine.Description}</td>
                                                            <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                            <td className='text-center'>{proLine.systemQuantity}</td>
                                                            <td className='text-center'>{proLine.realQuantity}</td>
                                                            <td className='text-center'>{proLine.difference}</td>
                                                            <td className='text-center'>{proLine.DifferenceMAS90}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCost))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCostMAS90))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalValued))}</td>
                                                            <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                            <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                            <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>


                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="diffActItems" role="tabpanel" aria-labelledby="diffActItems">
                                <div className='row pt-4'>
                                    <div className='col-12 pb-3'>
                                        <input onChange={this.searchNotEqualDifferencesCycleInventory} className="form-control form-control-lg" placeholder='Search by ItemCode, Product Line, Description...' id='searchDiffActCycleInventory' type="text" />
                                    </div>
                                    <div className='col-12 pb-3'>
                                        <ExcelDocument
                                            hidden={false}
                                            archname={'Actual Cycle Inventory Not Equal Differences ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                            sheetname={'Actual Cycle Inventory Not Equal Differences ' + getValueCookie('Company')}
                                            data={getDataSet(this.state.FilterAllNotEqualDifferences, ['Item Code', 'Product Line', 'Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode', 'productLine', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'DifferenceMAS90', 'TotalCost', 'TotalCostMAS90', 'TotalValued', 'date', 'countBy', 'comentary'])}

                                        />
                                    </div>
                                    <div hidden={this.state.AllErrorItems.length === 0} className='col-12 pb-2 tableFixHead'>
                                        <table className='table align-middle table-hover'>
                                            <thead className='thead'>
                                                <tr className='text-light text-center'>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('ItemCode', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('productLine', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('Description', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('BIN', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('systemQuantity', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('realQuantity', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('difference', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('DifferenceMAS90', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalCost', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Total Cost System</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalCostMAS90', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Total Cost MAS90</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' ></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('TotalValued', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('date', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('countBy', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    <th className='bg-dark' onClick={() => this.GeneralOrder('comentary', this.state.order, 'FilterAllNotEqualDifferences')}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.state.FilterAllNotEqualDifferences.map((proLine, i) => (
                                                        <tr key={i}>
                                                            <td className='text-start'>{proLine.ItemCode}</td>
                                                            <td className='text-start'>{proLine.productLine}</td>
                                                            <td className='text-start'>{proLine.Description}</td>
                                                            <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                            <td className='text-center'>{proLine.systemQuantity}</td>
                                                            <td className='text-center'>{proLine.realQuantity}</td>
                                                            <td className='text-center'>{proLine.difference}</td>
                                                            <td className='text-center'>{proLine.DifferenceMAS90}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCost))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalCostMAS90))}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(proLine.TotalValued))}</td>
                                                            <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                            <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                            <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <ModalOrders title={'Inventory Detail Product Line: ' + this.state.currentLineProd.ProLine.Name} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Product Line:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentLineProd.ProLine.Name}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Total Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.currentLineProd.ProLine.TotalItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Actual Count Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.currentLineProd.ProLine.TotalCountItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Ok Items: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.currentLineProd.ProLine.TotalOKItem}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-6 titleTotal'>
                                            <p>Error Items:</p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{this.state.currentLineProd.ProLine.TotalWrongItem}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Binary Accuracy: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{pricesFormat(this.state.currentLineProd.ProLine.BinaryAccuracy) + " %"}</p>
                                        </div>
                                        <div className='col-6 titleTotal'>
                                            <p>Percentage of Advance: </p>
                                        </div>
                                        <div className='col-6 TitleText'>
                                            <p>{pricesFormat(this.state.currentLineProd.ProLine.Advance) + " %"}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12 pb-3'>
                                    <input onChange={this.searchCurrentLineProdCycleInventory} className="form-control form-control-lg" placeholder='Search by ItemCode, Description...' id='searchBodyFilterCycleInventory' type="text" />

                                </div>
                                <div className='col-12 pb-3'>
                                    <ExcelDocument
                                        hidden={false}
                                        archname={'Actual Cycle Inventory KPI Product Line ' + this.state.currentLineProd.ProLine.Name + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                        sheetname={'Actual Cycle Inventory KPI Product Line ' + this.state.currentLineProd.ProLine.Name + " " + getValueCookie('Company')}
                                        data={getDataSet(this.state.currentLineProd.BodyFilter, ['Item Code', 'Description', 'BIN', 'System Quantity', 'Real Quantity', 'System Difference', 'MAS90 Diffrence', 'System Total Cost ($)', 'MAS90 Total Cost ($)', 'Total Valued ($)', 'Date', 'Counted By', 'Comment'], ['ItemCode', 'Description', 'BIN', 'systemQuantity', 'realQuantity', 'difference', 'DifferenceMAS90', 'TotalCost', 'TotalCostMAS90', 'TotalValued', 'date', 'countBy', 'comentary'])}

                                    />
                                </div>

                            </div>
                        </div>
                        <div className='col-1'></div>
                        <div className='col-12 pb-5 tableFixHead'>

                            <table className='table align-middle table-hover'>
                                <thead className='thead'>
                                    <tr className='text-light text-center'>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'ItemCode', this.state.order)}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'Description', this.state.order)}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'BIN', this.state.order)}><div className='row'><div className='col-12'>BIN</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'systemQuantity', this.state.order)}><div className='row'><div className='col-12'>System Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'realQuantity', this.state.order)}><div className='row'><div className='col-12'>Real Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'difference', this.state.order)}><div className='row'><div className='col-12'>System Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'DifferenceMAS90', this.state.order)}><div className='row'><div className='col-12'>MAS90 Difference</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'TotalCost', this.state.order)}><div className='row'><div className='col-12'>System Total Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'TotalCostMAS90', this.state.order)}><div className='row'><div className='col-12'>MAS90 Total Cost</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark'></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'TotalValued', this.state.order)}><div className='row'><div className='col-12'>Total Valued</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>

                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'date', this.state.order)}><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'countBy', this.state.order)}><div className='row'><div className='col-12'>Counted By</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th className='bg-dark' onClick={() => this.OrderSpecificInformation(this.state.currentLineProd.BodyFilter, 'comentary', this.state.order)}><div className='row'><div className='col-12'>Comment</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        this.state.currentLineProd.BodyFilter.map((proLine, i) => (
                                            <tr className={proLine.difference !== proLine.DifferenceMAS90 ? 'redButton' : ''} key={i}>
                                                <td className='text-start'>{proLine.ItemCode}</td>
                                                <td className='text-start'>{proLine.Description}</td>
                                                <td className='text-center'>{proLine.BIN || proLine.BIN === 'null' ? 'Not Available' : proLine.BIN}</td>
                                                <td className='text-center'>{proLine.systemQuantity}</td>
                                                <td className='text-center'>{proLine.realQuantity}</td>
                                                <td className='text-center'>{proLine.difference}</td>
                                                <td className='text-center'>{proLine.DifferenceMAS90}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{pricesFormat(proLine.TotalCost)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{pricesFormat(proLine.TotalCostMAS90)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{pricesFormat(proLine.TotalValued)}</td>
                                                <td className='text-center'>{proLine.date !== "" ? FormatQueryReturnDate(proLine.date) : 'Not Counted Yet'}</td>
                                                <td className='text-center'>{proLine.countBy !== "" ? proLine.countBy : 'Not Counted Yet'}</td>
                                                <td className='text-start'>{proLine.countBy !== "" ? proLine.comentary : 'Not Counted Yet'}</td>
                                            </tr>
                                        ))

                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </ModalOrders>
            </div>
        )
    }
}
