import React, { Component } from 'react'
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import "../../../css/inventory-style.css"
import LoadingWindow from '../../../components/LoadingWindow';
import { getInformationWithDataGlobalSales } from '../../../services/CABE';
import { BsArrowRepeat } from 'react-icons/bs'
import { getIndexElement } from '../../../functions/searchInObject';
import { GeneralInventoryOverStock, YearMonthDetail } from './Class';
import { NumberFormat } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDataSetOverstockInv } from '../../../functions/generateDataSetExcel';
import { getDateFromReports } from '../../../functions/dateFormat';
import { getValueCookie } from '../../../services/cookieService';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
export default class InventoryOverstockView extends Component {
    constructor(props) {
        super(props)

        this.ModalLoading = React.createRef();

    }

    state = {
        AllData: [],
        FilterAllData: [],
        Head1: [],
        Head2: [],
        ExcessColor: 2.5,
        MissColor: 1.5,
        Order: true


    }

    async getReportInfo() {
        this.ModalLoading.current.showState(true);
        var date1 = document.getElementById('startInventoryOverstock').value
        var date2 = document.getElementById('endInventoryOverstock').value
        let processData = []
        let dates = []
        if (date1 && date2) {
            const data = {
                Start: date1,
                End: date2
            }
            const result = await getInformationWithDataGlobalSales('/invoice/report/overstock', data)

            if (result) {
                let items = Object.keys(result)
                for (const Item of items) {
                    let det = []
                    let years = Object.keys(result[Item].detail)

                    for (const year of years) {
                        let tempInfoYear = result[Item].detail[year]
                        let months = Object.keys(tempInfoYear.detail)
                        let tempProm = new YearMonthDetail(14, Number(year), tempInfoYear.QuantityShipped.total)
                        for (const month of months) {
                            const index = dates.indexOf(month + '-' + year)
                            if (index === -1) {
                                dates.push(month + '-' + year)
                            }
                            let tempInfoMonth = tempInfoYear.detail[month]
                            det.push(new YearMonthDetail(Number(month), Number(year), tempInfoMonth.QuantityShipped.total))
                            tempProm.addCount(1)
                        }
                        det.push(new YearMonthDetail(13, Number(year), tempInfoYear.QuantityShipped.total))
                        det.push(tempProm)

                    }


                    let newItem = new GeneralInventoryOverStock(Item, result[Item].ItemCodeDesc, result[Item].ProductLineDesc, det, result[Item].QuantityShipped.promedio, result[Item].Warehouse000, result[Item].Warehouse001, result[Item].Warehouse002)
                    processData.push(newItem)
                }



            }

            for (const date of dates) {
                let splitDate = date.split('-')
                for (const item of processData) {
                    item.addEmpMonthYear(Number(splitDate[1]), Number(splitDate[0]))
                }
            }


            this.getHeaders(processData)
            this.setState({ AllData: processData, FilterAllData: processData })

        }
        this.ModalLoading.current.showState(false);
    }

    getHeaders(element) {
        let Head1 = []
        let Head2 = []
        for (const item of element) {
            for (const element of item.Detail) {
                const index = getIndexElement(Head1, 'Year', String(element.Year))
                if (index === -1) {
                    Head1.push({ Year: element.Year, spanCol: 1 })
                } else {
                    Head1[index].spanCol += 1
                }

                Head2.push(element.MonthName)
            }
            break
        }
        this.setState({ Head1: Head1, Head2: Head2 })
    }

    selectColor(Level) {
        if (Level < this.state.MissColor) {
            return "softYellow"
        } else if (Level >= this.state.MissColor && Level <= this.state.ExcessColor) {
            return "softGreen"
        } else {
            return "softRed"
        }
    }

    onChange = (e) => {
        const id = e.target.id
        switch (id) {
            case "OverStockLevel":
                this.setState({ ExcessColor: Number(e.target.value) })
                break
            case "ShortageLevel":
                this.setState({ MissColor: Number(e.target.value) })
                break
            default:
                break
        }
        this.FilterBySelectedOptions()
    }

    checkFilterItems(id, Type) {
        const checkbox = document.getElementById(id);

        if (Type === 'All' && checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportOverstockInv')
            for (const checkbox of checkboxList) {
                checkbox.checked = true;
            }
        } else if (Type === 'All' && !checkbox.checked) {
            const checkboxList = document.getElementsByClassName('ReportOverstockInv')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;
            }
        } else {
            let cond = true
            const checkboxList = document.getElementsByClassName('ReportOverstockInv')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckInvOverStockAll')) {
                    cond = false
                    break
                }
            }
            let all = document.getElementById('CheckInvOverStockAll')
            if (all) {
                all.checked = cond
            }


        }
        this.FilterBySelectedOptions()
    }

    FilterBySelectedOptions() {

        const checkRoute = document.getElementsByClassName('ReportOverstockInv')

        let Items = []
        for (const option of checkRoute) {

            if (option.checked) {
                Items.push(option.name)
            }

        }


        let FilterItems = []
        for (const Item of Items) {
            if (Item === 'All') {
                FilterItems = this.state.AllData
                break
            } else {
                for (const ItemCode of this.state.AllData) {
                    if (Item === "ShortageItems" && ItemCode.MOH < this.state.MissColor) {
                        FilterItems.push(ItemCode)
                    } else if (Item === "NormalItems" && ItemCode.MOH >= this.state.MissColor && ItemCode.MOH <= this.state.ExcessColor) {
                        FilterItems.push(ItemCode)
                    } else if (Item === "OverStockItems" && ItemCode.MOH > this.state.ExcessColor) {
                        FilterItems.push(ItemCode)
                    }
                }
            }
        }

        this.setState({ FilterAllData: FilterItems })

    }
    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }

    searchItems = e => {
        this.FilterBySelectedOptions()
        let search = e.target.value;
        let Results = []
        if (search) {
            Results = this.state.AllData.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.Description, search) ||
                    this.contains(item.ProductLine, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
        } else {
            Results = this.state.AllData
        }

        this.setState({ FilterAllData: Results });
    }

    orderInfo(columName) {
        let order = this.state.Order
        const temporal = this.state.AllData.sort((a, b) => {
            if (order) {
                return a[columName] > b[columName] ? 1 : a[columName] < b[columName] ? -1 : 0;
            } else {
                return a[columName] < b[columName] ? 1 : a[columName] > b[columName] ? -1 : 0;
            }
        });
        this.setState({ AllData: temporal, Order: !order })
    }

    orderDetailInfo(position,key) {
        console.log(this.state.Order)
        let order = this.state.Order
        const temporal = this.state.AllData.sort((a, b) => {
            if (order) {
                return a.Detail[position][key] > b.Detail[position][key] ? 1 : a.Detail[position][key] < b.Detail[position][key] ? -1 : 0;
            } else {
                return a.Detail[position][key] < b.Detail[position][key] ? 1 : a.Detail[position][key] > b.Detail[position][key] ? -1 : 0;
            }
        });
        this.setState({ AllData: temporal, Order: !order })
    }



    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Inventory Status</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select a start and end date.</p>
                </div>
                <div className='row pb-2'>
                    <div className='col-1' />
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <p className='text-center'>Start</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startInventoryOverstock' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <p className='text-center'>End</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endInventoryOverstock' type="date" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getReportInfo()} type="button" className="btn purpleButton btn-lg"><BsArrowRepeat /> Load data</button>
                                </div>
                            </div>
                            <div hidden={this.state.AllData.length === 0} className='col-sm-12'>
                                <div className='row TitleText'>
                                    <div className='col-md-6 col-sm-12'>
                                        <p className='text-center'>OverStock Level:</p>
                                        <div className="input-group input-group-lg pb-4 flex-nowrap" >
                                            <input className="form-control form-control-lg text-center" id='OverStockLevel' defaultValue={this.state.ExcessColor} onChange={(e) => this.onChange(e)} type="number" />
                                            <span className="input-group-text">months</span>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <p className='text-center'>Shortage Level:</p>
                                        <div className="input-group input-group-lg pb-4  flex-nowrap" >
                                            <input className="form-control form-control-lg text-center" id='ShortageLevel' defaultValue={this.state.MissColor} onChange={(e) => this.onChange(e)} type="number" />
                                            <span className="input-group-text">months</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div hidden={this.state.AllData.length === 0} className='col-sm-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='d-flex justify-content-evenly flex-wrap gap-4'>
                                            <div className="form-check text-start fontLarger textRadioButtonSize ">
                                                <label className="form-check-label w-100" >
                                                    All Items
                                                    <input className="form-check-input ReportOverstockInv" type="checkbox" name="All" defaultChecked onChange={() => this.checkFilterItems('CheckInvOverStockAll', 'All')} id={'CheckInvOverStockAll'} />
                                                </label>
                                            </div>
                                            <div className="form-check text-start fontLarger textRadioButtonSize ">
                                                <label className="form-check-label w-100" >
                                                    <div className="color-box backLeyendSoftRed" />
                                                    OverStock
                                                    
                                                    <input className="form-check-input ReportOverstockInv" type="checkbox" name="OverStockItems" onChange={() => this.checkFilterItems('CheckInvOverStockOverStockItm', 'OverStockItems')} defaultChecked id={'CheckInvOverStockOverStockItm'} />
                                                </label>
                                            </div>
                                            <div className="form-check text-start fontLarger textRadioButtonSize ">
                                                <label className="form-check-label w-100" >
                                                <div className="color-box backLeyendSoftGreen" />
                                                    Normal
                                                    <input className="form-check-input ReportOverstockInv" type="checkbox" name="NormalItems" onChange={() => this.checkFilterItems('CheckInvOverStockNormalItm', 'NormalItems')} defaultChecked id={'CheckInvOverStockNormalItm'} />
                                                </label>
                                            </div>
                                            <div className="form-check text-start fontLarger textRadioButtonSize ">
                                                <label className="form-check-label w-100" >
                                                <div className="color-box backLeyendSoftYellow" />
                                                    Shortage
                                                    <input className="form-check-input ReportOverstockInv" type="checkbox" name="ShortageItems" onChange={() => this.checkFilterItems('CheckInvOverStockShortageItm', 'ShortageItems')} defaultChecked id={'CheckInvOverStockShortageItm'} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-4'>
                                    <div className='col-12'>
                                        <div className="d-grid gap-2">
                                            <ExcelDocument
                                                hidden={false}
                                                archname={'Inventory Report' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Inventory Report ' + getValueCookie('Company')}
                                                data={getDataSetOverstockInv(this.state.Head1, this.state.Head2, this.state.FilterAllData, this.state.MissColor, this.state.ExcessColor)}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div hidden={this.state.AllData.length === 0} className='col-sm-12 pt-3'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input placeholder='Search by ItemCode, Description, ProductLine...' className="form-control form-control-lg" id='SearchOverStockReport' onChange={(e) => this.searchItems(e)} type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1' />
                    <div hidden={this.state.AllData.length === 0} className='col-sm-12 tableFixHead'>
                        <table className='table table-hover'>
                            <thead className='thead'>
                                <tr className='bg-dark text-white'>
                                    <th className='text-center bg-dark' colSpan={3}></th>
                                    {
                                        this.state.Head1.map((head, h) => (
                                            <th className='text-center bg-dark' colSpan={head.spanCol}>{head.Year}</th>
                                        ))
                                    }
                                    <th className='text-center bg-dark' colSpan={8}></th>
                                </tr>
                                <tr className='bg-dark text-white'>
                                    <th onClick={() => this.orderInfo("ItemCode")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("Description")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("ProductLine")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    {
                                        this.state.Head2.map((head, h) => (
                                            <th onClick={()=>this.orderDetailInfo(h,"Total")} key={h + "_head"} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>{head}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        ))
                                    }
                                    <th onClick={() => this.orderInfo("TotalAVG")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>General Average</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("OnHand000")} className='text-center bg-secondary'><div className='row text-center'><div className='col-12'>{"\n000\n(ATL)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("OnHand001")} className='text-center bg-secondary'> <div className='row text-center'><div className='col-12'>{"\n001\n(FL)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("OnHand002")} className='text-center bg-secondary'> <div className='row text-center'><div className='col-12'>{"\n002\n(Freezer)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("TotalOnHand")} className='text-center bg-dark'> <div className='row text-center'><div className='col-12'>On Hand</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("MOH")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>MOH</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("InvMonth")} className='text-center bg-dark'><div className='row text-center'><div className='col-12'>{"MOH\nOverStock/\nShortage"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th onClick={() => this.orderInfo("Quantity")} className='text-center bg-dark'> <div className='row text-center'><div className='col-12'>{"Quantity\nOverStock/\nShortage"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.FilterAllData.map((element, e) => (
                                        <tr key={e} className={this.selectColor(element.MOH)}>
                                            <td className='text-center'>{element.ItemCode}</td>
                                            <td className='text-center'>{element.Description}</td>
                                            <td className='text-center'>{element.ProductLine}</td>
                                            {element.Detail.map((det, d) => (
                                                <React.Fragment key={element.ItemCode + '_' + d}>
                                                    <td className='text-center'>{det.MonthNo === 14 ? NumberFormat(det.AVG) : NumberFormat(det.Total)}</td>
                                                </React.Fragment>
                                            ))}
                                            <td className='text-center'>{NumberFormat(element.TotalAVG)}</td>
                                            <td className='text-center'>{NumberFormat(element.OnHand000)}</td>
                                            <td className='text-center'>{NumberFormat(element.OnHand001)}</td>
                                            <td className='text-center'>{NumberFormat(element.OnHand002)}</td>
                                            <td className='text-center'>{NumberFormat(element.TotalOnHand)}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(element.MOH))}</td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(element.InvMonth))}</td>
                                            <td className='text-center'>{NumberFormat(Number(element.Quantity))}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot className='tfoot'>

                            </tfoot>
                        </table>
                    </div>
                </div>


            </div>
        )
    }

}