import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import LoadingWindow from '../../components/LoadingWindow';
import { getValueCookie } from '../../services/cookieService';
import Select from 'react-select';
import { getInformationWithDataGlobalSales } from '../../services/CABE';
import { ListOfUsers, UpdateByUsers, Users } from './Class';
import { getIndexElement } from '../../functions/searchInObject';
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { confirmCloseAlert } from '../../functions/alerts';
import { AleatoryNumber } from '../../functions/generalFunctions';
import md5 from 'md5'

export default class UsersManagement extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    state = {
        optionsSelect: [],
        selectedOption: new Users('', '', '', '', '', getValueCookie('CompanyId')),
        newUser: new UpdateByUsers("", "", "", "", "", "", "", "", "", "")

    }

    async getUsers() {
        const result = await getInformationWithDataGlobalSales('/user/List', null)
        if (result.data) {
            let newListUser = new ListOfUsers()

            for (const User of result.data) {
                newListUser.addUser(User, getValueCookie('CompanyId'))
            }
            this.setState({ optionsSelect: newListUser.List })
        } else {
            this.setState({ optionsSelect: [] })
        }
    }
    async handleChange(e) {
        const change = e
        const result = await getInformationWithDataGlobalSales('/user/Permissions', change)
        if (result.data) {
            change.Modules = result.data
            await this.setState({ selectedOption: change })
        }
    }

    onChange = async (e, Name, SubName) => {
        const value = e.target.value
        const temporal = this.state.selectedOption
        if (Name !== 'Modules') {
            temporal[Name] = value
        } else {
            const index = getIndexElement(temporal.Modules, 'name', SubName)
            if (index !== -1) {
                const data = {
                    IdCompany: Number(getValueCookie('CompanyId')),
                    UserName: this.state.selectedOption.UserName,
                    IdModule: temporal.Modules[index].IdModule,
                    UpdateBy: getValueCookie('userName'),
                    Status: temporal.Modules[index].Status === 1 ? 0 : 1
                }
                const result = await getInformationWithDataGlobalSales('/user/set/Permissions', data)
                if (result) {
                    if (result.data.affectedRows) {
                        temporal.Modules[index].Status = temporal.Modules[index].Status === 1 ? 0 : 1
                    } else {
                        confirmCloseAlert('incorrect', 'Whoops Something going wrong. Please Try Again!')
                    }
                }
            }
        }

        this.setState({ selectedOption: temporal })
    }

    async UpdateUser() {
        const user = new UpdateByUsers(this.state.selectedOption.UserName, this.state.selectedOption.name, this.state.selectedOption.surname, this.state.selectedOption.phone, this.state.selectedOption.emailAddress, getValueCookie('userName'))
        const result = await getInformationWithDataGlobalSales('/user/update', user)

        if (result) {
            if (result.data.affectedRows) {
                confirmCloseAlert('correct', 'User information has been updated!')

            } else {
                confirmCloseAlert('incorrect', 'Whoops Something going wrong. Please Try Again!')
            }
        }
    }

    onChangeNewUser = async (e, Name) => {
        let value = e.target.value
        const temporal = this.state.newUser
        if (Name !== "name" && Name !== "surname") {
            temporal[Name] = value
        } else {
            temporal[Name] = value
            if (temporal.name !== "" && temporal.surname !== "") {
                let temp = temporal.name + "." + temporal.surname
                temp = temp.replace(/[\n\r\s]+/g, '');
                temp = temp.normalize("NFD").replace(/[\u0300-\u036f]/g, '');;
                temp = temp.toLowerCase();
                temporal["userName"] = temp
            }else{
                temporal["userName"]="" 
            }

        }
        this.setState({ newUser: temporal })
    }



    async CreateUser() {
        let temporal = this.state.newUser
        let randomPass = "User" + AleatoryNumber(1563, 9999)
        temporal.createBy = getValueCookie('userName')
        temporal.PassWord = md5(randomPass)
        if(temporal.userName!==""&&temporal.userName){
            const result=await getInformationWithDataGlobalSales('/user/create', temporal)
        if (result) {
            if (result.data.affectedRows) {
                confirmCloseAlert('correct', 'User information has been updated!')

            } else {
                confirmCloseAlert('incorrect', 'Whoops Something going wrong. Please Try Again!')
            }
        }
        }else{
            confirmCloseAlert('incorrect', 'You need to enter the name and the surname to create user!')
        }
        

    }


    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Users Management</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select an user.</p>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <ul className="nav nav-tabs" id="UsersManagementList" role="tablist">
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='UpdateUserUsersManagement' className="nav-link active" data-bs-toggle="tab" data-bs-target="#UpdateUserManagement" type="button" role="tab" aria-controls="UpdateUserManagement" aria-selected="true"> Update Users</button>
                                    </li>
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='CreateUserUsersManagement' className="nav-link" data-bs-toggle="tab" data-bs-target="#CreateUserManagement" type="button" role="tab" aria-controls="CreateUserManagement" aria-selected="false">Create User</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="UserManagementTab">
                                    <div className="tab-pane fade show active" id="UpdateUserManagement" role="tabpanel" aria-labelledby="UpdateUserManagement">
                                        <div class="card inventory-card">
                                            <div class="card-body text-center">
                                                <div className='row justify-content-center'>
                                                    <div className='col-12 col-md-6 pt-3 row'>
                                                        <div className='col-12 InputText pb-5'>
                                                            <label for="usernameUpdateInput" className="form-label">Username</label>
                                                            <Select value={this.state.selectedOption} onMenuOpen={() => this.getUsers()} options={this.state.optionsSelect} onChange={this.handleChange.bind(this)} className='fs-4 text-start' placeholder="Select User to Update" id="usernameUpdateInput" />
                                                        </div>
                                                        <div className='col-12 col-md-6 InputText' hidden={this.state.selectedOption.Modules == 0}>
                                                            <div>
                                                                <label className='form-label' for="nameUpdateInput">Name</label>
                                                                <div className="input-group input-group-lg">
                                                                    <input onChange={(e) => this.onChange(e, 'Name')} defaultValue={this.state.selectedOption.name} type="text" autoComplete='off' className="form-control" id="nameUpdateInput"/>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className='form-label' for="surnameUpdateInput">Surname</label>
                                                                <div className="input-group input-group-lg">
                                                                    <input onChange={(e) => this.onChange(e, 'Surname')} defaultValue={this.state.selectedOption.surname} type="text" autoComplete='off' className="form-control" id="surnameUpdateInput" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-6 InputText' hidden={this.state.selectedOption.Modules == 0}>
                                                            <div>
                                                                <label className='form-label' for="emailUpdateInput">Email</label>
                                                                <div className="input-group input-group-lg">
                                                                    <input onChange={(e) => this.onChange(e, 'emailAddress')} defaultValue={this.state.selectedOption.emailAddress} type="text" autoComplete='off' className="form-control" id="emailUpdateInput" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className='form-label' for="phoneUpdateInput">Phone</label>
                                                                <div className="input-group input-group-lg">
                                                                    <input onChange={(e) => this.onChange(e, 'phone')} defaultValue={this.state.selectedOption.phone} type="text" autoComplete='off' className="form-control" id="phoneUpdateInput" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-grid py-4">
                                                            <button hidden={this.state.selectedOption.Modules == 0} type="button" onClick={() => this.UpdateUser()} className="btn greenButton btn-lg"><BsFillPersonPlusFill /> Save Changes</button>
                                                        </div>
                                                        <div className='col-12' hidden={this.state.selectedOption.Modules == 0}>
                                                            <p className='text-center display-5 pb-2' >Available Modules to {getValueCookie('Company')}</p>
                                                        </div>
                                                        <div className='col-sm-12 tableFixHead' hidden={this.state.selectedOption.Modules == 0}>
                                                            <table className='table align-middle'>
                                                                <thead className='thead'>
                                                                    <tr className='bg-primary text-white'>
                                                                        <th className='text-center bg-primary '>Module Name</th>
                                                                        <th className='text-center bg-primary '>Status</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.selectedOption.Modules.map((element, e) => (
                                                                        <tr key={e}>
                                                                            <td className='text-center'>{element.name}</td>
                                                                            <td className='text-center'>
                                                                                <div className="TitleText text-center form-check input-group-lg form-switch form-check-inline">
                                                                                    <input onChange={(e) => this.onChange(e, 'Modules', element.name)} className="form-check-input checkboxSize" type="checkbox" checked={element.Status === 1 ? true : false} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="CreateUserManagement" role="tabpanel" aria-labelledby="CreateUserManagement">
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='row pt-3'>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="usernameCreateInput">Username</label>
                                                        <div className="input-group input-group-lg">
                                                            <input disabled value={this.state.newUser.userName} type="text" autoComplete='off' className="form-control" id="usernameCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="surnameCreateInput">Surname</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "surname")} type="text" autoComplete='off' className="form-control" id="surnameCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="addressCreateInput">Address</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "address")} type="text" autoComplete='off' className="form-control" id="addressCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="cityCreateInput">City</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "city")} type="text" autoComplete='off' className="form-control" id="cityCreateInput" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row pt-3'>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="nameCreateInput">Name</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "name")} type="text" autoComplete='off' className="form-control" id="nameCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="emailCreateInput">Email</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "emailAddress")} type="text" autoComplete='off' className="form-control" id="emailCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="phoneCreateInput">Phone</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "phone")} type="text" autoComplete='off' className="form-control" id="phoneCreateInput" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 InputText'>
                                                        <label className='form-label' for="countryCreateInput">Country</label>
                                                        <div className="input-group input-group-lg">
                                                            <input onChange={(e) => this.onChangeNewUser(e, "country")} type="text" autoComplete='off' className="form-control" id="countryCreateInput" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 pb-3'>
                                                <div className="d-grid gap-2">
                                                    <button type="button" onClick={() => this.CreateUser()} className="btn greenButton btn-lg"><BsFillPersonPlusFill /> Create New User</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}