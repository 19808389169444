import React, { Component } from 'react'
import "../../css/general-style.css"
import { AiFillPrinter } from "react-icons/ai"
import Select from 'react-select';
import SmallModal from '../SmallModal'
import { getIndexElement } from '../../functions/searchInObject'
import { getInformationWithData } from '../../services/CABE';
import { getValueCookie } from '../../services/cookieService';
import { PriceList } from './PriceList';
import { getPrintHeaderCompany } from '../../functions/companyInformation';
import LoadingWindow from '../../components/LoadingWindow';
import { FormatQueryReturnDateForReport, getActualDateUTC, getDateFromReports } from '../../functions/dateFormat';
import { pricesFormat } from '../../functions/pricesFormat';
import ExcelDocument from '../ExcelDocument';
import { getDataSet } from '../../functions/generateDataSetExcel';

export class PriceListModal extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }

    state = {
        showModal: false,
        lastRow: 0,
        companyPrintHeader: getPrintHeaderCompany(),
        selectOptions: [
            { value: 1, label: 'Price Level 1' },
            { value: 2, label: 'Price Level 2' },
            { value: 3, label: 'Price Level 3' }
        ],
        PriceLevel: 1,
        ProductLines: [],
        ProductsFilter: [],
        ProductsList: [],
        selectedAllProdLines: true,
        Customer: ''

    }
    handleModalOpen = async () => {
        await this.GetProducts()
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }
    onChangeSelects = async (e, id) => {

        switch (id) {
            case "PriceLevel":
                this.setState({ PriceLevel: e.value })
                break;
            default:
                break;
        }
        await this.GetProducts()


    }
    async GetProducts() {
        await this.ModalLoading.current.showState(true);
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: this.state.PriceLevel,
            Component: null
        }
        const respuesta = await getInformationWithData('/Items/get', data);
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const respuesta2 = await getInformationWithData('/Items/get', data);

            if (getValueCookie('CompanyId') === '6' && data.priceLevel !== '2') {
                for (const item of respuesta2.data) {

                    if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                        //751096

                        //let p = item.LastTotalUnitCost / (0.95)
                        //item.UnitPrice = Number(pricesFormat(p / (0.65)))
                        item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))

                    } else {
                        //ELIMINAR DEL CATALOGO
                        console.log(item.itemCode)

                    }
                }

            }
            respuesta.status.code = respuesta2.status.code
            //respuesta.data = respuesta.data.concat(respuesta2.data)
            for (const item of respuesta2.data) {
                item.IdCompany = 3
                const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await respuesta.data.splice(index, 1)
                }
            }
            for (const item of respuesta.data) {
                item.IdCompany = 6
                if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                    item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                }
            }

            respuesta.data = respuesta.data.concat(respuesta2.data)
        }


        let ProductLines = []
        for (const iterator of respuesta.data) {
            const index = getIndexElement(ProductLines, 'label', iterator.ProductLineDesc)
            if (index === -1) {
                ProductLines.push({ label: iterator.ProductLineDesc, selected: true })
            }
        }
        if (getValueCookie('CompanyId') === '6') {
            for (const item of respuesta.data) {
                console.log(item)
                if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                    item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                }
            }
        }


        await this.setState({ ProductLines: ProductLines, ProductsList: respuesta.data, ProductsFilter: respuesta.data, selectedAllProdLines: true })
        await this.ModalLoading.current.showState(false);
    }
    checkAllInv = async (e, actualStat) => {
        const temporal = this.state.ProductLines

        if (!actualStat) {
            temporal.forEach(element => {
                element.selected = true
            })

            await this.setState({ ProductLines: temporal, selectedAllProdLines: true })
        } else {
            temporal.forEach(element => {
                element.selected = false
            })

            await this.setState({ ProductLines: temporal, selectedAllProdLines: false })
        }

    }

    viewSelectedAll() {
        let temporal = true
        for (const iterator of this.state.ProductLines) {
            if (!iterator.selected) {
                temporal = false
                break
            }
        }
        this.setState({ selectedAllProdLines: temporal })
    }
    checkSpecificProdLine = async (e, actualStat, ProdLine) => {
        const temporal = this.state.ProductLines
        const index = getIndexElement(temporal, 'label', ProdLine)
        if (index !== -1) {
            temporal[index].selected = !actualStat
        }
        await this.setState({ ProductLines: temporal })
        await this.viewSelectedAll()
    }

    onChange = e => {
        this.setState({ Customer: e.target.value })
    }

    getPrintProducts() {

        if (this.state.selectedAllProdLines) {
            let items = []
            for (const item of this.state.ProductsList) {
                item.abbreviatedDesc = (item.completeDesc && item.completeDesc !== "null") ? item.completeDesc : item.abbreviatedDesc
                items.push(item)
            }
            return items
        } else {
            let ProdLines = []
            for (const ProdLine of this.state.ProductLines) {
                if (ProdLine.selected) {
                    ProdLines.push({ label: ProdLine.label })
                }
            }
            let items = []
            for (const item of this.state.ProductsList) {
                const index = getIndexElement(ProdLines, 'label', item.ProductLineDesc)
                if (index !== -1) {
                    item.abbreviatedDesc = (item.completeDesc && item.completeDesc !== "null") ? item.completeDesc : item.abbreviatedDesc
                    items.push(item)
                }
            }

            return items


        }
    }



    render() {
        return (
            <React.Fragment>
                <LoadingWindow ref={this.ModalLoading} />
                <div className="d-grid gap-2">
                    <button hidden={this.props.hidden} id={this.props.id} disabled={this.props.disabled} type="button" onClick={() => this.handleModalOpen()} className={"btn " + this.props.colorButton + " btn-lg"}><AiFillPrinter /> {this.props.titleButton}</button>
                </div>
                <SmallModal centered={true} size={'xl'} title={'Generate Price Level'} show={this.state.showModal} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='row pt-3'>
                                <div className='col-sm-12 InputTextBolder'>
                                    <p>Price Level:</p>
                                </div>
                                <div className=' pt-2 col-12'>
                                    <Select value={this.state.selectOptions[(getIndexElement(this.state.selectOptions, 'value', String(this.state.PriceLevel)))]} isDisabled={false} className='fs-4 text-start' options={this.state.selectOptions} onChange={(e) => this.onChangeSelects(e, "PriceLevel")} placeholder="Select Price Level" />
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <div className='col-sm-12 InputTextBolder'>
                                    <p>Customer:</p>
                                </div>
                                <div className=' pt-2 col-sm-12'>
                                    <div className="input-group input-group-lg">
                                        <input onChange={this.onChange} id='CustomerOfListPriceReport' type="text" autoComplete='off' className="form-control" disabled={false} />
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <div className='col-sm-12 InputTextBolder'>
                                    <p>Product Lines:</p>


                                </div>
                                <div className='col-sm-1' />
                                <div className='pt-2 col-12'>
                                    <div className='row'>
                                        <div className='col-sm-12 pb-3'>
                                            <div className='form-check'>
                                                <input onChange={(e) => this.checkAllInv(e, this.state.selectedAllProdLines)} className="btn-check" type="checkbox" value="" id={"flexCheckAll"} checked={this.state.selectedAllProdLines} />
                                                <label className="btn btn-outline-primary" htmlFor={"flexCheckAll"}>
                                                    All Product Lines
                                                </label>
                                            </div>
                                        </div>
                                        {this.state.ProductLines.map((element, e) => (
                                            <div className='col-md-4 col-6'>
                                                <div className='form-check' key={e}>
                                                    <input onChange={(e) => this.checkSpecificProdLine(e, element.selected, element.label)} className="btn-check" type="checkbox" value="" id={"flexCheck" + element.label} checked={element.selected} />
                                                    <label className="btn btn-outline-secondary" htmlFor={"flexCheck" + element.label}>
                                                        {element.label}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-sm-1' />
                                <div className='row pt-3'>
                                    <div className='col-sm-12 col-md-6 pb-4'>
                                        <PriceList id={"PriceListPrint"} colorButton="orangeButton" title="Price Level List"
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            OrderTitle="Items Price List"
                                            contactInfo={this.state.companyPrintHeader}
                                            OrderInfo1={["Customer: ", this.state.Customer, "Date: ", FormatQueryReturnDateForReport(getActualDateUTC()), "Print by: ", getValueCookie('userName'), "", ""]}
                                            headerTable={["Item Code", "", "Product Line", "Description", "", "Unit\nPrice*"]}
                                            bodyTable={this.getPrintProducts()}
                                            headerBodyTable={["itemCode", "", "ProductLineDesc", "abbreviatedDesc", "dolar", "UnitPrice"]}
                                            bottomInfo={[]}
                                            styleColumns={["ColumnaTextoNormalLeft", "Dolar", "ColumnaTextoLargo", "ColumnaTextoLargo3", "Dolar", "ColumnaPrecios"]}
                                            hidden={false}
                                            comment={"*These prices may change without prior notice"}
                                            breakpoint={48}
                                            keysDecimalFormat={["UnitPrice"]}
                                            disabled={false}
                                            titleButton='Print Price Level List'
                                            />
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <ExcelDocument
                                            hidden={false} 
                                            id={"ExcelInventoryData"} 
                                            data={getDataSet(this.getPrintProducts(),["Item Code", "Product Line", "Description", "Unit Price"],["itemCode", "ProductLineDesc", "abbreviatedDesc", "UnitPrice"])}
                                            sheetname={"Level "+this.state.PriceLevel} 
                                            archname={"Price List Level "+this.state.PriceLevel+" "+ getValueCookie('Company') + " DATE " + getDateFromReports()}
                                            />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                </SmallModal>

            </React.Fragment>

        )
    }
}

